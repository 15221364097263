import { Injectable } from '@angular/core';
import { AngularFirestore }  from '@angular/fire/firestore';

/** Interfaces */
import { Notifications } from 'app/interfaces/notifications'

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {


  constructor(private db: AngularFirestore) { }

  public saveNotifications(notification: Notifications) {
    this.db.collection('notifications').doc(`${notification.notification_id}`).set(notification);
  }

  


  public getNotifications() {
    return this.db.collection('notifications', ref => ref.orderBy('notification_id', 'desc')).valueChanges();
  }

  public getNotificationInfoFromNotificationId(notification_id: String) {
    return this.db.collection('notifications', ref => ref.where('notification_id','==',notification_id)).valueChanges()
  }

  public getNotificationInfoFromNotificationIdSaved(notification_id: String) {
    return this.db.collection('notificationsSaved', ref => ref.orderBy('notification_id','asc')).valueChanges()
  }


  public getNotificationsSavedFromUsers(notification_id: String) {
    return this.db.collection('notificationsSave', ref => ref.where('notification_id','==',notification_id)).valueChanges()

  }

  public getNotificationsSaved() {
    return this.db.collection('notificationsSave', ref => ref.orderBy('notification_id','desc')).valueChanges()

  }

  public deleteNotifications(documentId: string) {
    return this.db.collection("notifications").doc(documentId).delete();
  }


}
