import { Component, Input, OnInit } from '@angular/core';
import { tick } from '@angular/core/testing';
import { Notifications } from 'app/interfaces/notifications';
import { NotificationsService } from 'app/services/notifications/notifications.service';
import { UsersService } from 'app/services/users/users.service';

/** SERVICES  */
import { WishlistService} from 'app/services/wishlist/wishlist.service'
import swal from 'sweetalert2';
import { ProductService } from '../../services/product/product.service';
@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {

  @Input() infoUser: Users
  public arrayNotificationsInfo: Array<Notifications> = [];
  public arrayWishList: Array<Wishlist> = []
  public arrayShowAllNotification: Array<Boolean> = [];
  
  constructor(private wishListService: WishlistService,
              private notificationService: NotificationsService,
              private usersService: UsersService,
              private productService: ProductService
              ) { }

  ngOnInit(): void {
    this.getWishlistInfo();
    this.geNotificationsFromUser();
  }
  /**
   * 1. SE CONSULTA EL TOTAL WISHLIST PUBLICATIONS
   */

  public getWishlistInfo() {
    // console.log(this.arrayWishList);

    
    this.wishListService.getWishlistPublications(this.infoUser.user_email).subscribe(wishlists => {
      // console.log(wishlists)
      wishlists.forEach(element => {
        
        // console.log(element.product_id);
        
      });
      this.arrayWishList = wishlists;
      
      if (this.arrayWishList.length === wishlists.length)
      this.getWishlistProducts()
    })
  }
  /**
   * 1. SE CONSULTA EL TOTAL WISHLIST PRODUCTOS
   */
  public getWishlistProducts() {
    this.wishListService.getWishlistProducts(this.infoUser.user_email).subscribe(wishlistsProducts => {
      //console.log(wishlistsProducts)
      wishlistsProducts.forEach(product => {
        this.productService.getProductById(product).subscribe((p: Wishlist) => {
          p.user_id_stand = product.user_id_stand;
          p.user_whatsapp = product.user_whatsapp;
          this.arrayWishList.push(p);
        })
      })
    })
  }

  // public getInfoNotifications(notificationsId: String) {
  //   this.usersService
  //     .getNotificationsSavedFromUsers(notificationsId)
  //     .subscribe((notification) => {
  //       //console.log(notification);

  //       this.arrayNotificationsInfo.push(notification[0]);
  //     });
  // }

  /**
   * 
   * @param wishlist 
   * @param i 
   * 1. SE ELIMINA UN WISHLIST EN ESPECIFICO.
   */
  public deleteWishlist(wishlist: any, i) {
    if (wishlist.publication_id) {
      this.wishListService.deleteWishlitInUser(wishlist.publication_id, this.infoUser.user_email);
    } else {
      this.wishListService.deleteWishlitProductInUser(wishlist.product_id, this.infoUser.user_email);
    }
    this.arrayWishList.splice(i, 1)
    swal("OK", "Se ha eliminado correctamente", "success");
  }



/**
   * 
   * @param notificacion 
   * @param i 
   */
  public deleteNotificacion(notificacion: Notifications, i) {
    // console.log(notificacion)
    if (notificacion.notification_id) {
      // console.log(notificacion.notification_id)
      this.usersService.deleteNotification(notificacion.notification_id, this.infoUser)
    }
    this.arrayNotificationsInfo.splice(i, 1)
    swal("OK", "Se ha eliminado correctamente", "success");
  }





  public geNotificationsFromUser() {
    this.arrayShowAllNotification = [];
    this.arrayNotificationsInfo = [];
    // console.log(this.infoUser.user_email);

    this.usersService
      .getNotificationsSavedFromUsers(this.infoUser.user_email)
      .subscribe((notifications) => {
        this.arrayNotificationsInfo = [];
        let count = 0;
        //console.log(notifications);

        notifications.forEach((n) => {
          this.getInfoNotifications(n.notification_id);
          this.arrayShowAllNotification[count] = false;
          count++;
        });
      });
  }

  public getInfoNotifications(notificationsId: String) {
    this.notificationService
      .getNotificationInfoFromNotificationId(notificationsId)
      .subscribe((notification) => {
        // console.log(notification);

        this.arrayNotificationsInfo.push(notification[0]);
      });
  }

  public toggleContent(i) {
    this.arrayShowAllNotification[i] = true;
  }
  public toggleContentHiden(i) {
    this.arrayShowAllNotification[i] = false;
  }

  public upDateStatusNotification(notification_id) {
    swal({
      title: "Alerta",
      text: "¿Está seguro que desea eliminar esta notificación?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn btn-fill btn-success btn-mr-5",
      cancelButtonClass: "btn btn-fill btn-danger",
      confirmButtonText: "Sí, eliminar!",
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
    }).then(async (result) => {
      
      if (result.value) {
        this.usersService.updateStatusNotificationSaved(
          notification_id,
          this.infoUser.user_email
        );
        this.arrayNotificationsInfo = [];
      }


    }
    )}



}
