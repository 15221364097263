import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "app/services/auth/auth/auth.service";
import { DatesService } from "app/services/dates/dates.service";
import { ubicationUserService } from "app/services/ubicationUser/ubicationUser.service";
import { functions } from "firebase";
declare var $: any;
@Component({
  selector: "app-navbarindex",
  templateUrl: "./navbarindex.component.html",
  styleUrls: ["./navbarindex.component.css"],
})
export class NavbarindexComponent implements OnInit {
  @Input() route: string;
  @Input() onRegisterTypeClick: Function;

  public modalNavName = "navModalIndex";
  public infoUser: Users;
  // public route: string = "";
  public typeRegister: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dateServices: DatesService,
    public ubicationService: ubicationUserService
  ) {}

  ngOnInit(): void {
    this.infoUser = JSON.parse(localStorage.getItem("infoUser"));
  }

  logout() {
    this.doLogout();
  }

  doLogout() {
    this.authService.logout();
    this.router.navigate(["/"]);
    this.infoUser = JSON.parse(localStorage.getItem("infoUser"));
  }

  public viewEventsAll() {
    this.router.navigate(["/events/all"]);
    setTimeout(() => {
      $("html, body").animate(
        {
          scrollTop: $("#comunidadScroll").offset().top,
        },
        800,
        function () {}
      );
    }, 800);
  }

  public viewStandAll() {
    this.route = "/pages/stores/all";
  }

  public UserRegisterUbication() {
    console.log("CREADO");
    this.infoUser = JSON.parse(localStorage.getItem("infoUser"));
    this.infoUser.user_register_date = this.dateServices.getDateCurrent();
    this.infoUser.user_register_time = this.dateServices.getTimeCurrent();
    this.ubicationService.createUbicationTotal(this.infoUser);
  }

  public routeNav() {
    // this.router.navigate(['/events/all'])
    // console.log("ruta");
    this.typeRegister = "1";
    setTimeout(() => {
      console.log(this.modalNavName);
      if (
        $("#" + this.modalNavName).attr("aria-hidden") === "true" ||
        !$("#" + this.modalNavName).hasClass("show")
      ) {
        // Si el modal está oculto, lo mostramos.
        $("#" + this.modalNavName).modal("show");
      } else {
        console.log("El modal ya está abierto.");
      }
    }, 500);
  }
}
