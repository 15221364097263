import {
  Component,
  ViewChild,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { Location } from "@angular/common";
import "rxjs/add/operator/filter";
import { PagesnavbarComponent } from "../../shared/pagesnavbar/pagesnavbar.component";
declare var $: any;

@Component({
  selector: "app-layout",
  templateUrl: "./auth-layout.component.html",
})
export class AuthLayoutComponent {
  location: Location;
  private _router: Subscription;

  @ViewChild(PagesnavbarComponent) pagesnavbar: PagesnavbarComponent;
  constructor(private router: Router, location: Location) {
    this.location = location;
  }
  onActivate(event) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
}
  ngOnInit() {
    this._router = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event) => {});
  }
}
