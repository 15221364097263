import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AuthService } from "app/services/auth/auth/auth.service";
import { Router } from "@angular/router";
import * as firebase from "firebase";
import swal from "sweetalert2";
declare var $: any;

declare interface UserLogin {
  email?: string;
  password?: string;
}

@Component({
  selector: "app-modal-login",
  templateUrl: "./modal-login.component.html",
  styleUrls: ["./modal-login.component.css"],
})
export class ModalLoginComponent implements OnInit {
  @Input() route: string;
  @Input() typeRegister: string;
  @Input() modalName: string;
  @ViewChild('dynamicModal', { static: false }) modalLogin: ElementRef;
  public userLogin: UserLogin;
  public islogging = false;

  array = [];
  loginForm = new FormGroup({
    email: new FormControl("", [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
    ]),
    password: new FormControl("", Validators.required),
  });
  test: Date = new Date();
  loginService: any;

  constructor(private authservice: AuthService, private router: Router) {}

  ngOnInit() {
    if (this.typeRegister === undefined) {
      this.typeRegister = "2";
    }
    this.userLogin = {};

    this.modalName = this.modalName ?? "modalLogin";
  }

  /**
   * *** Registro con Facebook ***
   */
  public registerFacebook() {
    this.authservice.loginFacebookUser();
  }

  /**
   * *** Registro con Google ***
   */
  public registerGoogle() {
    this.authservice.loginGoogleUser();
  }

  /**
   * *** Enrutamos el sistema para la creacion de nuevos usuarios ***
   */
  public newUserTypeTwo() {
    $("#" + this.modalName).modal("hide");
    console.log("enrutar a registro");
    this.router.navigate(["/pages/register/" + "email"]);
  }

  /**
   * *** Manejo del modal login       ***
   * *** seteamos la variable a false ***
   * *** para ocultar el cargando     ***
   */
  ngAfterViewInit() {

    if (this.modalName) {
      const modalElement = document.querySelector(`#${this.modalName}`);
      if (modalElement) {
        this.modalLogin = new ElementRef(modalElement); // Asigna manualmente un ElementRef.
      } else {
        console.error(`Modal with ID '${this.modalName}' not found.`);
        return;
      }
    }

  
    $(this.modalLogin.nativeElement).on("hidden.bs.modal", () => {
      this.islogging = false;
    });
    $(this.modalLogin.nativeElement).on("show.bs.modal", () => {
      // console.log('*** this.route ***');
      // console.log(this.typeRegister);
      // console.log(this.route);
    });
  }

  /**
   * *** Funcion para loguear al usuario           ***
   * *** validamos el formulario si es valido      ***
   * *** llamamos el servicio login en authSevice  ***
   * @param userLogin
   * @param valid
   */
  async onLogin(userLogin: UserLogin, valid: boolean) {
    this.array.length;

    if (valid) {
      if (userLogin) {
        this.islogging = true;
        this.authservice.login(userLogin.email, userLogin.password, this.route);

        $("#" + this.modalName).modal("hide");
      }
    }
  }

  /**
   * *** Funcion para iniciar el proceso de recuperacion de clave ***
   * @param userLogin
   * @param valid
   */
  async recoverPass(userLogin: UserLogin, valid: boolean) {
    // (userLogin, valid, "HASTA AQUI");

    await this.authservice.sendEmailRecoverPass(userLogin);
    // console.log(userLogin, valid, "HASTA AQUI 1");

    var auth = firebase.auth();
    // console.log(userLogin, valid, "HASTA AQUI 2" );

    var emailAddress = userLogin.email;
    auth
      .sendPasswordResetEmail(emailAddress)
      .then(function () {
        swal(
          "Recuperando constraseña",
          "Por favor revisa tu correo electrónico para poder recuperar tu contraseña",
          "success"
        );

        // console.log("entroooo!!!")
      })
      .catch(function (error) {
        swal("ERROR", "Algo salio mal", "success");

        // console.log("no entra")
      });

    // console.log("no entra! REVISA")
  }

  /**
   * *** Funcion para iniciar el proceso de recuperacion de clave ***
   * @param userLogin
   * @param valid
   */

  recuperar(userLogin: UserLogin) {
    var auth = firebase.auth();
    var emailAddress = userLogin.email;

    auth
      .sendPasswordResetEmail(emailAddress)
      .then(function () {
        // console.log("entroooo!!!")
      })
      .catch(function (error) {
        // console.log("no entra")
      });
  }
}
