import { Routes } from "@angular/router";
import { IndexComponent } from "./index/index.component";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { EnventosComponent } from "./modules/eventos/enventos/enventos.component";
import { ModulesLayoutComponent } from "./layouts/modules/modules-layout.component";
import { PerfilComponent } from "./modules/perfil/perfil/perfil.component";
import { ListPublicationsComponent } from "./modules/listPublications/list-publications/list-publications.component";

export const AppRoutes: Routes = [
  { path: "index", component: IndexComponent },
  { path: "", component: IndexComponent },
  { path: "community", component: ListPublicationsComponent },
    
  {
    path: "",
    component: ModulesLayoutComponent,
    children: [
      { path: "events/:id_event", component: EnventosComponent },
      { path: "perfil/:email", component: PerfilComponent },
      { path: "perfil", component: PerfilComponent },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "categories",
        loadChildren: "./modules/categoria/categoria.module#CategoriaModule",
      },
      {
        path: "novelties",
        loadChildren: "./modules/novelties/novelties.module#NoveltiesModule",
      },
      {
        path: "users",
        loadChildren: "./modules/users/users.module#UsersModule",
      },
      {
        path: "home",
        loadChildren: "./modules/home/home.module#HomeModule",
      },
      {
        path: "products",
        loadChildren: "./modules/products/products.module#ProductsModule",
      },
      {
        path: "countries",
        loadChildren: "./modules/countries/countries.module#CountriesModule",
      },
      {
        path: "notifications",
        loadChildren:
          "./modules/notifications/notifications.module#NotificationsModule",
      },
      {
        path: "message",
        loadChildren: "./modules/message/message.module#MessageModule",
      },
      {
        path: "statistics",
        loadChildren: "./modules/statistics/statistics.module#StatisticsModule",
      },
      {
        path: "charts",
        loadChildren: "./modules/charts/charts.module#ChartsModule",
      },
      {
        path: "denuncias",
        loadChildren: "./modules/denuncias/denuncias.module#DenunciasModule",
      },
      {
        path: "list-users",
        loadChildren: "./modules/list-users/list-users.module#ListUsersModule",
      },
      {
        path: "list-post",
        loadChildren: "./modules/list-post/list-post.module#ListPostModule",
      },
      {
        path: "list-posts",
        loadChildren: "./modules/list-posts/list-posts.module#ListPostsModule",
      },
      
      
      // {
      //   path: "community",
      //   loadChildren: "./modules/listPublications#ListPublicationsComponent",
      // },
    ],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "pages",
        loadChildren: "./pages/pages.module#PagesModule",
      },
    ],
  },
];
