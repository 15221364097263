import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore'
import { Community } from 'app/interfaces/community';
@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  constructor( private db: AngularFirestore) {
   }

   public savePublicCommunity(community: Community){
     const country = {
      country_name: community.country
     }
    this.db.collection('community').doc(`${community.country}`).set(country)
     this.db.collection('community').doc(`${community.country}`).collection('publications').doc(`${community.publication_id}`).set(community);
   }

   public getPublications(country: String ,user_id: String) {
    return this.db.collection('community').doc(`${country}`)
    .collection('publications',ref => ref.where('user_id', '==', user_id).orderBy("publication_id", "desc")).valueChanges()
   }

   public getPublicationById(userCountry: String, publicationId: String) {
     return this.db.collection('community').doc(`${userCountry}`).collection('publications').doc(`${publicationId}`).valueChanges()
   }
   public getPublicationsByUserId(userCountry: String, user_id: String) {
    return this.db.collection('community').doc(`${userCountry}`).collection('publications', ref => ref.where("user_id", "==", user_id)).valueChanges()
  }
   public updateImgLogo(userCountry: String, publicationId: String, urlImage: any){
     const img = {
      user_image: urlImage
     }
    return this.db.collection('community').doc(`${userCountry}`).collection('publications').doc(`${publicationId}`).update(img)
   }

   public getPublicationsLength(userCountry: String) {
    return  this.db.collection('community').doc(`${userCountry}`).collection('publications', 
    ref => ref.orderBy("publication_id", "desc")).valueChanges()
   }

   public saveCommentary(userCountry: String, publication_id: String, commentary: Commentary) {
     this.db.collection('community').doc(`${userCountry}`).collection('publications').doc(`${publication_id}`).collection('comments')
     .doc(`${commentary.commentary_id}`).set(commentary)
   }
   public getComments(userCountry: String, publication_id: String) {
    return this.db.collection('community').doc(`${userCountry}`).collection('publications')
    .doc(`${publication_id}`).collection('comments', ref => ref.orderBy('commentary_id', 'desc')).valueChanges()
  }

  public getPublicationsByCountries() {
    return this.db.collection('community').valueChanges()
  }
}
