import { Component, Input, OnInit } from "@angular/core";
import swal from "sweetalert2";
/** SERVICES */
import { UsersService } from "app/services/users/users.service";
import { AuthService } from "app/services/auth/auth/auth.service";
import { CommunityService } from "app/services/community/community.service";
import { WishlistService } from "app/services/wishlist/wishlist.service";
/** COMMUNITY */
import { Community } from "app/interfaces/community";
import { DatesService } from "app/services/dates/dates.service";
declare var $: any;

@Component({
  selector: "app-list-publications",
  templateUrl: "./list-publications.component.html",
  styleUrls: ["./list-publications.component.css"],
})
export class ListPublicationsComponent implements OnInit {
  @Input() infoUser: Users;
  @Input() isEventModule: boolean;
  @Input() isEventAll: boolean;
  @Input() isViewCommunity: boolean;
  public user: Users;
  public arrayIdsPublicationsInteresting: Array<Community> = [];
  public arrayIdsPublications: Array<Community> = [];
  public arrayListPublications: Array<Community> = [];
  public listPublications: Array<Community> = [];
  public filterPublications: Array<Community> = [];
  public countPosts: number;
  public areTheLastPost = false;
  public allPosts: Array<Community> = [];
  public newComment: Array<boolean> = [false, false, false];
  public commentary: Commentary;
  public arrayComments: Commentary[];
  public publicacionSelected: String = '';
  public arrayUsers: Commentary[];
  public bandera: boolean =false;
  public arrayEmojis: Array<any>
  public countries: Array<Country>;
  public l1 = 0;
  public l2 = 0;
  public arrayWishList: Array<Community>;
  constructor(
    private usersService: UsersService,
    private authService: AuthService,
    private CommunityService: CommunityService,
    private dateServices: DatesService,
    private wishlistService: WishlistService
  ) { }

  ngOnInit(): void {
    
    this.arrayEmojis= [
      '😀','😃','😄','😁','😆','😅','😂','🤣','😊','😇','🙂','🙃','😉','😌','😍','😘',
      '😗','😙','😚','😋','😛','😝','😜','🤓','😎','😏','😒','😞','😔',
      '😟','😕','🙁','😣','😖','😫','😩','😢','😭','😤','😠','😡','😳'
      ,'😱','😨','😰','😥','😓','🤗','🤔','🤥','😶','😐','😑','😬','🙄','😯','😦',
      '😧','😮','😲','😴','🤤','😪','😵','🤐','🤢','🤧','😷','🤒','🤕','🤑','🤠',
      '😈','👿','👹','👺','🤡','💩','👻','💀','☠️','👽','👾','🤖','🎃','😺','😸','😹',
      '😻','😼','😽','🙀','😿','😾','👶','👦','👧','👱','👨',
      '👴','👵','🙍','🙍‍♂️','🙍‍♀️','🙎','🙎‍♂️','🙎‍♀️','🙅','🙅‍♂️','🙅‍♀️','🙆','🙆‍♂️','🙆‍♀️','💁','💁‍♂️','💁‍♀️',
      '🙋','🙋‍♂️','🙋‍♀️','🙇‍♂️','🙇‍♀️','🤦','🤦‍♂️','🤦‍♀️',
      '🤷','🤷‍♂️','🤷‍♀️','👨‍⚕️','👩‍⚕️','👨‍🎓','👩‍🎓','👨‍🏫','👩‍🏫','👨‍⚖️','👩‍⚖️','👨‍🌾','👩‍🌾','👨‍🍳','👩‍🍳','👨‍🔧','👩‍🔧','👨‍🏭','👩‍🏭',
      '👨‍💼','👩‍💼','👨‍🔬','👩‍🔬','👨‍💻','👨‍🎤','👩‍🎤','👨‍🎨','👩‍🎨','👨‍✈️','👩‍✈️','👨‍🚀','👩‍🚀',
      '👨‍🚒','👩‍🚒','👮','👮‍♂️','👮‍♀️','🕵','🕵️‍♂️','🕵️‍♀️','💂','💂‍♂️','💂‍♀️','👷','👷‍♂️','👷‍♀️','🤴','👸','👳','👳‍♂️','👳‍♀️'
      ,'🤵','👰','🤰','👼','🎅','🤶','💆','💆‍♂️','💆‍♀️','💇','💇‍♂️',
      '💇‍♀️','🚶','🚶‍♂️','🚶‍♀️','🏃','🏃‍♂️','🏃‍♀️','💃','🕺','🕴',
      '👯','👯‍♂️','👯‍♀️','👭','👫','👬','💏','👨‍❤️‍💋‍👨','👩‍❤️‍💋‍👩',
      '💑','👨‍❤️‍👨','👩‍❤️‍👩','👪','👨‍👩‍👦','👨‍👩‍👧','👨‍👩‍👧‍👦','👨‍👩‍👦‍👦','👨‍👩‍👧‍👧','👨‍👨‍👦','👨‍👨‍👧','👨‍👨‍👧‍👦','👨‍👨‍👦‍👦','👨‍👨‍👧‍👧','👩‍👩‍👦','👩‍👩‍👧','👩‍👩‍👧‍👦','👩‍👩‍👦‍👦'
      ,'👩‍👩‍👧‍👧','👨‍👦','👨‍👦‍👦','👨‍👧','👨‍👧‍👦','👨‍👧‍👧','👩‍👦','👩‍👦‍👦','👩‍👧','👩‍👧‍👦','👩‍👧‍👧','🗣','👤','👥','👣','🌂','☂️',
      '👓','🕶','👔','👕','👖','👗','👘','👙','👚','👛',
      '👜','👝','🎒','👞','👟','👠','👡','👢','👑','👒','🎩','🎓','⛑','💄','💍','💼','🐶'
      ,'🐱','🐭','🐹','🐰','🦊','🐻','🐼','🐨','🐯','🦁','🐮','🐷','🐽','🐸','🐵','🙈','🙉','🙊','🐒','🐔',
      '🐧','🐦','🐤','🐣','🐥','🦆','🦅','🦉','🦇','🐺','🐗','🐴','🦄','🐝','🐛','🦋','🐌','🐞','🐜','🕷','🕸',
      '🦂','🐢','🐍','🦎','🐙','🦑','🦐','🦀','🐡','🐠','🐟','🐬','🐳','🐋','🦈',
      '🐊','🐅','🐆','🦍','🐘','🦏','🐪','🐫','🐃','🐂','🐄','🐎','🐖','🐏','🐑',
      '🐐','🦌','🐕','🐩','🐈','🐓','🦃','🕊','🐇','🐁','🐀',
      '🐿','🐾','🐉','🐲','🌵','🎄','🌲','🌳','🌴','🌱','🌿','☘️','🍀','🎍','🎋','🍃','🍂','🍁','🍄','🐚','🌾',
      '💐','🌷','🌹','🥀','🌺','🌸','🌼','🌻','🌞','🌝','🌛','🌜','🌚','🌕','🌖','🌗','🌘','🌑','🌒','🌓','🌔','🌙',
      '🌎','🌍','🌏','💫','⭐️','🌟','✨','⚡️','☄️','💥','🔥','🌪','🌈','☀️','🌤','⛅️','🌥','☁️','🌦','🌧','⛈','🌩',
      '🌨','❄️','☃️','⛄️','🌬','💨','💧','💦','☔️','☂️','🌊','🌫','🍏','🍎','🍐','🍊','🍋','🍌','🍉','🍇','🍓','🍈','🍒',
      '🍑','🍍','🥝','🍅','🍆','🥑','🥒','🌶','🌽','🥕','🥔','🍠','🥐','🍞','🥖','🧀','🥚','🍳','🥞','🥓','🍗','🍖',
      '🌭','🍔','🍟','🍕','🥙','🌮','🌯','🥗',
      '🥘','🍝','🍜','🍲','🍛','🍣','🍱',,'🍤','🍙','🍚','🍘','🍥','🍢','🍡','🍧','🍨','🍦',
      '🍰','🎂','🍮','🍭','🍬','🍫','🍿','🍩','🍪','🌰','🥜','🍯','🥛','🍼','☕️','🍵','🍶','🍺','🍻',
      '🥂','🍷','🥃','🍸','🍹','🍾','🥄','🍴','🍽','⚽️','🏀','🏈','⚾️','🎾','🏐','🏉',
      '🎱','🏓','🏸','🏒','🏑','🏏','🥅','⛳️','🏹','🎣','🥊','🥋','🎽','⛸','🎿','⛷','🏂','🏋️','🏋️‍♂️','🏋️‍♀️',
      '🤼','🤼‍♂️','🤼‍♀️','🤸‍♀️','🤸','🤸‍♂️','⛹️','⛹️‍♂️','⛹️‍♀️','🤺','🤾','🤾‍♂️','🤾‍♀️','🏌️','🏌️‍♂️','🏌️‍♀️','🏇','🏄‍♀️','🏊','🏊‍♂️','🏊‍♀️','🤽',
      '🤽‍♂️','🤽‍♀️','🚣','🚣‍♂️','🚣‍♀️','🚵','🚵‍♂️','🚵‍♀️','🚴','🚴‍♂️','🚴‍♀️','🏆','🥇',
      '🥈','🥉','🏅','🎖','🏵','🎗','🎫','🎟','🎪','🤹','🤹‍♂️','🤹‍♀️','🎭','🎨','🎬','🎤','🎧','🎼','🎹','🥁','🎷','🎺','🎸'
      ,'🎻','🎲','♟','🎯','🎳','🎮','🎰','🚗','🚕','🚙','🚌','🚎','🏎','🚓','🚑','🚒','🚐','🚚','🚛','🚜','🛴','🚲','🛵',
      '🏍','🚨','🚔','🚍','🚘','🚖','🚡','🚠','🚟','🚃','🚋','🚞','🚝','🚄','🚅','🚈','🚂','🚆','🚇',
      '🚊','🚉','✈️','🛫','🛬','🛩','💺','🛰','🚀','🚁','🛶','⛵️','🚤','🛥','🛳','⛴','🚢','⚓️','⛽️','🚧','🚦','🚥','🚏','🗺',
      '🗿','🗽','🗼','🏰','🏯','🏟','🎡','🎢','🎠','⛲️','⛱','🏖','🏝','🏜','🌋','⛰','🏔','🗻','🏕','⛺️','🏠','🏡','🏘','🏚','🏗',
      '🏭','🏢','🏬','🏣','🏤','🏥','🏦','🏨','🏪','🏫','🏩','💒','🏛','⛪️','🕌','🕍','🕋','⛩','🛤','🛣','🗾','🎑','🏞','🌅',
      '🌄','🌠','🎇','🎆','🌇','🌆','🏙','🌃','🌌','🌉','🌁','⌚️','📱','📲','💻','⌨️','🖥','🖨','🖱','🖲','🕹','🗜','💽','💾','💿',
      '📀','📼','📷','📸','📹','🎥','📽','🎞','📞','☎️','📟','📠','📺','📻','🎙','🎚','🎛','⏱','⏲','⏰','🕰','⌛️','⏳','📡',
      '🔋','🔌','💡','🔦','🕯','🛢','💸','💵','💴','💶','💷','💰','💳','💎','⚖️','🔧','🔨','⚒','🛠','⛏','🔩','⚙️',
      ,'⛓','🔫','💣','🔪','🗡','⚔️','🛡','🚬','⚰️','⚱️','🏺','🔮','📿','💈','⚗️','🔭','🔬','🕳',
      '💊','💉','🌡','🚽','🚰','🚿','🛁','🛀','🛎','🔑','🗝','🚪','🪑',
      '🛋','🛏','🛌','🖼','🛍','🛒','🎁','🎈','🎏','🎀','🎊','🎉','🎎','🏮','🎐','✉️','📩','📨','📧','💌','📥','📤','📦',
      '🏷','📪','📫','📬','📭','📮','📯','📜','📃','📄','📑','📊','📈','📉','🗒','🗓','📆','📅','🗑','📇','🗃','🗳','🗄','📋','📁',
      '📂','🗂','🗞','📰','📓','📔','📒','📕','📗','📘','📙','📚','📖','🔖','🔗','📎','🖇','📐','📏','📌','📍','✂️','🖊','🖋',
      '✒️','🖌','🖍','📝','✏️','🔍','🔎','🔏','🔐','🔒','🔓','❤️','💛','💚','💙','💜','🖤','💔','❣️','💕','💞','💓',
      '💗','💖','💘','💝','💟','☮️','✝️','☪️','🕉','☸️','✡️','🔯','🕎','☯️','☦️','🛐','⛎','♈️','♉️','♊️','♋️','♌️','♍️','♎️',
      '♏️','♐️','♑️','♒️','♓️','🆔','⚛️','🉑','☢️','☣️','📴','📳','🈶','🈚️','🈸','🈺','🈷️','✴️','🆚','💮','🉐','㊙️','㊗️','🈴',
      '🈵','🈹','🈲','🅰️','🅱️','🆎','🆑','🅾️','🆘','❌','⭕️','🛑','⛔️','📛','🚫','💯','💢','♨️','🚷','🚯','🚳','🚱','🔞','📵',
      '🚭','❗️','❕','❓','❔','‼️','⁉️','🔅','🔆','〽️','⚠️','🚸','🔱','⚜️','🔰','♻️','✅','🈯️','💹','❇️','✳️','❎','🌐','💠','Ⓜ️',
      '🌀','💤','🏧','🚾','♿️','🅿️','🈳','🈂️','🛂','🛃','🛄','🛅','🚹','🚺','🚼','🚻','🚮','🎦','📶','🈁','🔣','ℹ️','🔤','🔡',
      '🔠','🆖','🆗','🆙','🆒','🆕','🆓','0️⃣','1️⃣','2️⃣','3️⃣','4️⃣','5️⃣','6️⃣','7️⃣','8️⃣','9️⃣','🔟','🔢','#️⃣','*️⃣','⏏️','▶️','⏸',
      '⏯','⏹','⏺','⏭','⏮','⏩','⏪','⏫','⏬','◀️','🔼','🔽','➡️','⬅️','⬆️','⬇️','↗️','↘️','↙️','↖️','↕️','↔️','↪️','↩️','⤴️',
      '⤵️','🔀','🔁','🔂','🔄','🔃','🎵','🎶','➕','➖','➗','✖️','♾','💲','💱','™️','©️','®️','〰️','➰','➿','🔚','🔙','🔛','🔝',
      '🔜','✔️','☑️','🔘','🔴','🔵','⚫️','⚪️','🔺','🔻','🔸','🔹','🔶','🔷','🔳','🔲','▪️','▫️','◾️','◽️',
      '◼️','◻️','⬛️','⬜️','🔈','🔇','🔉','🔊','🔔','🔕','📣','📢','👁‍🗨','💬','💭','🗯','♠️','♣️','♥️'
      ,'♦️','🃏','🎴','🀄️','🕐','🕑','🕒','🕓','🕔','🕕','🕖','🕗','🕘','🕙','🕚','🕛','🕜','🕝','🕞','🕟','🕠','🕡','🕢','🕣','🕤',
      '🕥','🕦','🕧'
    ]


this.authService.verifyUser();
this.commentary={};
if (this.infoUser !== null) {
      this.getPublicationsLength();
      this.getWishlistPublications();

    }
  }

  seleccionarEmoji(emoji: any)
  {
    //console.log(emoji);
    if(this.commentary.commentary_description== undefined)
    {
      this.commentary.commentary_description=emoji
    }
    else 
    {
      this.commentary.commentary_description= this.commentary.commentary_description +' '+emoji;
    }
    
    
  }

  abrirEmojis(){
    //console.log(this.bandera);
    this.bandera= !this.bandera
    
  }
  /**
   * 1. SE OBTIENE LAS PUBLICACIONES DE LA COLECCIÓN COMMUNITY
   */
  public getPublicationsLength() {
    //console.log(this.infoUser.user_country);

    this.CommunityService.getPublicationsLength(
      this.infoUser.user_country
    ).subscribe((pl) => {
      this.allPosts = pl;
      this.countPosts = this.allPosts.length;
      this.getPublicationsOfInteresting();
    });
  }

  /**
   * 1. SE OBTIENE LOS IDS DE PUBLICACIONES **DE INTERES**
   * 2. SE DESORDENA EL ARRAY PARA MOSTRAR EN ORDEN DIFERENTE CADA QUE SE CARGA EL COMPONENTE
   */
  public async getPublicationsOfInteresting() {
    await this.usersService
      .getPublicationsByUserInteresting(this.infoUser.user_email)
      .subscribe(async (publications) => {
        var j, x, i;
        this.l1 = publications.length;
        if (publications.length > 2) {
          for (i = publications.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = publications[i];
            publications[i] = publications[j];
            publications[j] = x;
            publications.forEach((pub) => {
              if (this.arrayIdsPublications.includes(pub)) {
                return;
              } else {
                this.arrayIdsPublications.push(pub);
                if (this.arrayIdsPublications.length === publications.length) {
                  this.getPublications();
                }
              }
            });
          }
        } else if (publications.length > 0 && publications.length < 3) {
          this.arrayIdsPublications = publications;
          await this.getPublications();
        } else if (publications.length === 0) {
          await this.getPublications();
        }
      });
  }

  /**
   * 1. SE OBTIENES LAS PUBLICACIONES QUE SE HAN AGREGADO A MIS WISHLIST
   */
  public getWishlistPublications() {
    this.wishlistService
      .getWishlistPublications(this.infoUser.user_email)
      .subscribe((productWishlist) => {
        this.arrayWishList = productWishlist;
      });
  }

  /**
   * 1. SE OBTIENE LOS IDS DE PUBLICACIONES DE *** NO INTERES ***
   */
  public async getPublications() {
    //console.log('*** getPublications ***');

    await this.usersService
      .getPublications(this.infoUser.user_email)
      .subscribe((publications) => {
        // console.log(publications);

        this.l2 = publications.length;
        if (publications.length > 2) {
          var j, x, i;
          for (i = publications.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = publications[i];
            publications[i] = publications[j];
            publications[j] = x;
            publications.forEach((pub) => {
              //console.log(pub);
              if (this.arrayIdsPublications.includes(pub)) {
                return;
              } else {
                this.arrayIdsPublications.push(pub);
                if (this.arrayIdsPublications.length === this.l1 + this.l2) {
                  this.getInfoPublication(
                    this.arrayIdsPublications,
                    this.infoUser.user_country
                  );
                }
              }
            });
          }
        } else if (publications.length > 0 && publications.length < 3) {
          publications.forEach((p) => {
            //console.log(p);
            this.arrayIdsPublications.push(p);
            if (this.arrayIdsPublications.length === this.l1 + this.l2) {
              this.getInfoPublication(
                this.arrayIdsPublications,
                this.infoUser.user_country
              );
            }
          });
        } else if (publications.length === 0) {
          this.allPosts.forEach((p) => {
            if (this.allPosts.length > 3) {
              //console.log(p);
              this.arrayListPublications.push(p);
              if (this.filterPublications.length < 3) {
                if (this.filterPublications.includes(p)) {
                  return;
                } else {
                  //console.log(p);
                  this.filterPublications.push(p);
                  //console.log(p);
                
                  if (this.filterPublications.length === 3) {
                    this.randomPublications(this.filterPublications);
                  }
                }
              }
            } else {
              if (this.filterPublications.includes(p)) {
                return;
              } else {
                this.filterPublications.push(p);
                if (this.filterPublications.length === this.allPosts.length) {
                  this.randomPublications(this.filterPublications);
                }
              }
            }
          });
        }
      });
  }

  /**
   *
   * @param arrayPublications
   * @param country
   *
   * 1. SE CONSULTA LA INFORMACIÓN DE LOS IDS OBTENIDOS.
   */

  public getInfoPublication(arrayPublications: Array<Community>, country: String) {
    arrayPublications.forEach(publication => {
      this.CommunityService.getPublicationById(country, publication.publication_id).subscribe( p => {
        //console.log('PUBBBBBBBBBBBBBBBBBBBBB', p === undefined);
        if (p !== undefined) {
          this.arrayListPublications.push(p)
        }
       
        if (this.arrayListPublications.length > 3) {
          if (this.filterPublications.length < 3) {
            if (this.filterPublications.includes(p)) {
              return;
            } else {
              if (this.filterPublications.length < 3) {
                this.filterPublications.push(p)
              }
              if (this.filterPublications.length === 3) {
                this.randomPublications(this.filterPublications)
              }
            }
          }
        } else {
          this.filterPublications.push(p)
          if (this.filterPublications.length === this.arrayListPublications.length) {
            this.randomPublications(this.filterPublications)
          }
        }

      })
    })
  }


  // public getInfoPublication(
  //   arrayPublications: Array<Community>,
  //   country: String
  // ) {
  //   arrayPublications.forEach((p) => {
  //     this.CommunityService.getPublicationById(
  //       country,
  //       p.publication_id,
  //     ).subscribe((p) => {
  //       //console.log("la peeeee12",p);
  //       if(p !== undefined){
  //         //console.log("la peeeee13",p);
  //         this.arrayListPublications.push(p);
          
  //       }
  //       if (arrayPublications.length > 3) {
  //           if (this.filterPublications.length < 3) {
  //             if (this.filterPublications.includes(p)) {
  //               //console.log("la peeeee1",p);
  
  //               return;
  //             } else {
  //               if (this.filterPublications.length < 3) {
  //                 this.filterPublications.push(p);
  //                 //console.log("la peeeee2",p);
  //               }
  //               if (this.filterPublications.length === 3) {
  //                 this.randomPublications(this.filterPublications);
  //                 //console.log("aqui sale 3")
  //               }
  //             }
  //           }
  //         } else {
  //           this.filterPublications.push(p);
  //           if (this.filterPublications.length === arrayPublications.length) {
  //             this.randomPublications(this.filterPublications);
  //             //console.log("aqui sale 4c")
  
  //           }
  //         }
       
  //     });
  //   });
  // }

  /**
   *
   * @param arrayPublications
   *
   * 1. SE VALLIDA SI LAS TRES PUB A MOSTRAR ALGUNA ESTA AGREGADA A MI WISHLIST
   * 2. SE VALIDA QUE NO SE MUESTREN DOS PUBLICACIONES SEGUIDAS DEL MISMO INTERES
   */
  public randomPublications(arrayPublications: Array<Community>) {
    //console.log("ssss",arrayPublications);
    arrayPublications.forEach((publication) => {
      this.arrayWishList.forEach((pWishlist) => {
        if (pWishlist.publication_id === publication.publication_id) {
          publication.publication_status_wishlist =
            pWishlist.publication_status_wishlist;
        }
      });
    });
    this.listPublications = [];
    if (arrayPublications.length === 3) {
      if (
        //console.log("HASTA AQUI1 "),
        arrayPublications[0].category !== arrayPublications[1].category &&
        arrayPublications[1].category !== arrayPublications[2].category


      ) {
        this.listPublications.push(arrayPublications[0]);
        this.listPublications.push(arrayPublications[1]);
        this.listPublications.push(arrayPublications[2]);
      } else if (
        arrayPublications[0].category === arrayPublications[1].category &&
        arrayPublications[0].category !== arrayPublications[2].category
      ) {
        this.listPublications.push(arrayPublications[0]);
        this.listPublications.push(arrayPublications[2]);
        this.listPublications.push(arrayPublications[1]);
      } else if (
        arrayPublications[1].category === arrayPublications[2].category &&
        arrayPublications[0].category !== arrayPublications[1].category
      ) {
        this.listPublications.push(arrayPublications[1]);
        this.listPublications.push(arrayPublications[0]);
        this.listPublications.push(arrayPublications[2]);
      } else if (
        arrayPublications[1].category === arrayPublications[2].category &&
        arrayPublications[0].category === arrayPublications[1].category
      ) {
        this.listPublications.push(arrayPublications[0]);
        this.listPublications.push(arrayPublications[1]);
        this.listPublications.push(arrayPublications[2]);
      }
    } else if (arrayPublications.length === 2) {
      this.areTheLastPost = true;
      this.listPublications.push(arrayPublications[1]);
      this.listPublications.push(arrayPublications[0]);
    } else if (arrayPublications.length === 1) {
      this.areTheLastPost = true;
      this.listPublications.push(arrayPublications[0]);
    }
  }

  /**
   *  SE CONSULTAN TRES PUBLICACIONES MAS
   */
  public async viewMore() {
    $("html, body").animate(
      {
        scrollTop: $("#div-content").position().top,
      },
      800,
      function () { }
    );
    this.newComment = [false, false, false];
    let count = 0;
    this.filterPublications = [];
    this.listPublications.forEach((pub) => {
      count++;
      const indice = this.arrayListPublications.indexOf(pub);
      this.arrayListPublications.splice(indice, 1);
    });
    if (count === this.listPublications.length) {
      if (
        this.arrayListPublications.length > 0 &&
        this.arrayListPublications.length > 3
      ) {
        this.arrayListPublications.forEach((p) => {
          if (this.filterPublications.length < 3) {
            this.filterPublications.push(p);
          }
          if (this.filterPublications.length === 3) {
            this.randomPublications(this.filterPublications);
          }
        });
      }
      if (
        this.arrayListPublications.length > 0 &&
        this.arrayListPublications.length < 3
      ) {
        this.filterPublications = this.arrayListPublications;
        this.areTheLastPost = true;
        this.randomPublications(this.filterPublications);
      }
    }
  }

  /**
   *
   * @param publication_id
   * 1. SE GUARDAN LOS COMENTARIOS DE LA PUBLICACION
   */
  public async saveComent(publication_id: string) {
    var commentary_id = new Date().getTime();
    this.commentary.commentary_id = commentary_id.toString();
    this.commentary.commentary_time_register = this.dateServices.getTimeCurrent();
    this.commentary.commentary_date_register = this.dateServices.getDateCurrent();
    this.commentary.commentary_user = this.infoUser.user_name;
    this.commentary.commentary_user_lastname = this.infoUser.user_lastname;
    this.commentary.commentary_state = true;
    this.commentary.commentary_num_like = 0;
    this.commentary.commentary_i_do_not_like = 0;
    // this.infoUser.user_image_perfil 
    // ? this.commentary.commentary_user_image = this.infoUser.user_image_perfil
    // : this.commentary.commentary_user_image = this.infoUser.user_url_logo;

    if (this.infoUser.user_image_perfil) {
      this.commentary.commentary_user_image = this.infoUser.user_image_perfil
    } else if (this.infoUser.user_url_logo) {
      this.commentary.commentary_user_image = this.infoUser.user_url_logo;
    } else {
      this.commentary.commentary_user_image = ''
    }


    await this.CommunityService.saveCommentary(
      this.infoUser.user_country,
      publication_id,
      this.commentary
    );
    this.commentary = {};
  }

  /**
   *
   * @param publication_id
   * @param i
   * @param pubCountry
   *
   * 1. SE CONSULTAN LOS COMENTARIOS DE UNA PUBLIACIÓN EN ESPECIFICA
   */

  public viewComments(publication_id: String, i, pubCountry?: String) {
    this.publicacionSelected = publication_id;
    this.newComment = [false, false, false];
    this.newComment[i] = true;
    if (this.infoUser !== null) {
      this.CommunityService.getComments(
        this.infoUser.user_country,
        publication_id
      ).subscribe((comments) => {
        this.arrayComments = comments;
      });
    } else {
      this.CommunityService.getComments(pubCountry, publication_id).subscribe(
        (comments) => {
          this.arrayComments = comments;
        }
      );
    }
  }

  /**
   *
   * @param publication
   * @param i
   * 1. SE AGREGA UNA PUBLICACIÓN A MI WISHLIST
   */
 
  
  public addWishlist(publication: Community, i) {
    //console.log(publication);
    this.listPublications[i].publication_status_wishlist = !this
      .listPublications[i].publication_status_wishlist;
    if (this.listPublications[i].publication_status_wishlist === true) {
      this.wishlistService.saveWishlitInUser(
        publication.publication_id,
        publication,
        this.infoUser.user_email

      );
      swal("OK", "¡Se ha guardado en su Wishlist!", "success");
    } else {

      this.wishlistService.deleteWishlitInUser(
        publication.publication_id,
        this.infoUser.user_email
      );
      swal("OK", "¡Se ha eliminado de su Wishlist!", "success");

    }

  }
}




