import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { DenunciaComentario } from "app/interfaces/denunciaComentario";

@Injectable({
  providedIn: "root",
})
export class DenunciaService {
  constructor(private fb: AngularFirestore) {}

  /**
   * *** DENUNCIAS DE PERFIL ***
   * *** Crea una nueva Subcategoria dentro de la coleccion USERS ***
   * @param denuncia
   * @param infoUser
   */
  public createDenuncia(denuncia: Denuncia, infoUser: Users) {
    return this.fb
      .collection("users")
      .doc(infoUser.user_email)
      .collection("denuncia")
      .doc(denuncia.denuncia_uid)
      .set(denuncia);
  }

  /**
   * *** Obtenemos todas las denuncias de un usuario ***
   * @param userID
   */
  public getDenuncias(userID: string) {
    return this.fb
      .collection("users")
      .doc(userID)
      .collection("denuncia")
      .snapshotChanges();
  }

  /**
   * Denuncias de comentarios
   * @param dC
   * @param comentario
   * @param evento
   */
  public saveDenunciaComentario(
    dC: DenunciaComentario,
    commentary: Commentary,
    novelty: Novelty,
    user: Users
  ) {
    var data = {
      commentary: commentary,
      complaint: dC,
      novelty: novelty,
      user: user,
    };
    /// *** Guardamos el novelty denunciado ***
    return this.fb
      .collection("comment_complaint")
      .doc(commentary.commentary_id)
      .set(data);
  }
}
