import { Component, OnInit, Renderer2, ElementRef  } from '@angular/core';

import { Location } from "@angular/common";
import { CategoryService } from "app/services/categorias/categorias.service";
import * as firebase from "firebase/app";
import { MessageService } from "app/services/message/message.service";
import { DatesService } from "app/services/dates/dates.service";
import { Pipe, PipeTransform } from "@angular/core";
import swal from "sweetalert2";
import { Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { NoveltyService } from "app/services/novelty/novelty.service";
import { UsersService } from "app/services/users/users.service";
declare var $: any;


@Component({
  selector: 'app-footerbbc',
  templateUrl: './footerbbc.component.html',
  styleUrls: ['./footerbbc.component.css']
})
export class FooterbbcComponent implements OnInit {
  year: Date = new Date();
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  public arrayCategory: Category[];
  public arrayCategoryAux: Category[];
  public tests = "test";
  public arrayTest = [];
  public message: Message;
  public arrayNovelty: Novelty[];
  public arrayImgUsers: { url: string }[];
  public seachText = "";
  public islogging: boolean = true;
  public infoUser: Users;
  public arraySalesPrimium: Users[];
  public route = "";
  public routes1 = "/events/1604079835081";
  public typeRegister: string;

  constructor(
    location: Location,
    private renderer: Renderer2,
    private element: ElementRef,
    private categoryService: CategoryService,
    private messageService: MessageService,
    private datesService: DatesService,
    private noveltyService: NoveltyService,
    private usersService: UsersService,
    private router: Router
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit(): void {
    this.infoUser = JSON.parse(localStorage.getItem("infoUser"));
    this.route = "";
    this.arrayTest = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
    this.arrayCategory = [];
    this.arrayCategoryAux = [];
    this.arraySalesPrimium = [];
    this.getCategories();
    this.getNovelty();
    this.getSales();
    this.message = {};
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggle")[0];
  }

  /**
   * *** Optenemos todas las categorias de la DB ***
   */
  async getCategories() {
    await this.categoryService.getCategories().subscribe((categorySnapshot) => {
      this.arrayCategory = [];
      this.arrayCategoryAux = [];
      categorySnapshot.forEach((categoryData) => {
        this.arrayCategory.push(categoryData.payload.doc.data());
        this.arrayCategoryAux.push(categoryData.payload.doc.data());
      });
      this.islogging = false;
    });
  }

  /**
   * *** Obtenemos las tiendas premium ***
   */
  async getSales() {
    (await this.usersService.getUsersIsPremier()).subscribe((salesSnapshot) => {
      this.arraySalesPrimium = [];
      salesSnapshot.forEach((sale) => {
        this.arraySalesPrimium.push(sale.payload.doc.data());
      });
    });
  }

  /**
   * *** Obtenemos las novedades ***
   */
  async getNovelty() {
    await this.noveltyService.getNovelty().subscribe((noveltySnapshot) => {
      this.arrayNovelty = [];
      noveltySnapshot.forEach((noveltyData) => {
        this.arrayNovelty.push(noveltyData.payload.doc.data());
      });
    });
  }

  /**
   * *** funciones para el sideBar ***
   */
  sidebarOpen() {
    var toggleButton = this.toggleButton;
    var body = document.getElementsByTagName("body")[0];
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);
    body.classList.add("nav-open");
    this.sidebarVisible = true;
  }

  sidebarClose() {
    var body = document.getElementsByTagName("body")[0];
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    body.classList.remove("nav-open");
  }

  sidebarToggle() {
    if (this.sidebarVisible == false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  isLogin() {
    if (
      this.location.prepareExternalUrl(this.location.path()) === "#/pages/login"
    ) {
      return true;
    }
    return false;
  }

  isRegister() {
    if (
      this.location.prepareExternalUrl(this.location.path()) ===
      "#/pages/register"
    ) {
      return true;
    }
    return false;
  }

  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }

  /**
   * *** Funcion para almacenar los mensajes del usuario ***
   * *** Se obtine la información del formulario de contacto***
   * @param message
   * @param valid
   */
  onSaveMessage(message: Message, valid: boolean) {
    message.message_date = this.datesService.getDateCurrent();
    message.message_id = new Date().getTime().toString();
    message.message_time = this.datesService.getTimeCurrent();
    message.message_state = false;
    if (valid) {
      this.messageService.createMessage(message).then(() => {
        //console.log(message);
        swal("OK", "Mensaje enviado correctamente", "success");
        this.message = {};
      });
    }
  }

  /**
   * *** Limpiamos el formulario ***
   */
  onCancelMessage() {
    this.message = {};
  }

  /**
   * *** Buscador de catogorías ***
   */
  searchCategory() {
    this.arrayCategory = [];
    this.arrayCategoryAux.forEach((element) => {
      if (element.nombre.toUpperCase().includes(this.seachText.toUpperCase())) {
        this.arrayCategory.push(element);
      }
    });
  }

  /**
   * *** Arreglamos la ruta para ver los eventos ***
   * *** enviamos el id del evento ************* ***
   * @param noveltyId
   */
  public viewNovelty(noveltyId: String) {
    this.route = "/events/" + noveltyId;
  }

  public viewEventsAll() {
    this.route = "";
    this.route = "/events/all";
  }
  public viewEventsAlls() {
    this.route = "";
    this.route = "/events/alls";
  }s
  public viewStandAll() {
    this.route = "/pages/stores/all";
  }
}
