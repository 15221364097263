// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    /// *** desarrollo ***
    //  apiKey: "AIzaSyD1MREaAlMwdlk5rjfP-L9E9KU-HRmEO1c",
    //  authDomain: "buybuycenterdevelopment.firebaseapp.com",
    //  databaseURL: "https://buybuycenterdevelopment.firebaseio.com",
    //  projectId: "buybuycenterdevelopment",
    //  storageBucket: "buybuycenterdevelopment.appspot.com",
    //  messagingSenderId: "587675220754",
    //  appId: "1:587675220754:web:8fb7bdfd16ebea514e51cb"

    /// *** produccion ***
    apiKey: "AIzaSyCDuvI4OcJYZ8mhwa2kkMFjUlVJ1t8MYvc",
    authDomain: "buybuycenter-5d02b.firebaseapp.com",
    databaseURL: "https://buybuycenter-5d02b.firebaseio.com",
    projectId: "buybuycenter-5d02b",
    storageBucket: "buybuycenter-5d02b.appspot.com",
    messagingSenderId: "802764093766",
    appId: "1:802764093766:web:b2207cb76e909b730f4d47",
    measurementId: "G-70G7T5LDQ3"
    // apiKey: "AIzaSyCcJTbmPJpr6XnLHIw-Qnt9IHic_hj2l7Y",
    // authDomain: "globalnetworker-91359.firebaseapp.com",
    // projectId: "globalnetworker-91359",
    // storageBucket: "globalnetworker-91359.appspot.com",
    // messagingSenderId: "657787035784",
    // appId: "1:657787035784:web:e318765d706024219fff3d",
    // measurementId: "G-0W3NWY2JGC",
  },
};
