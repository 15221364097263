import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.css"],
})
export class ChatComponent implements OnInit {
  public novelty: Novelty;
  public showMenu = 1;
  public showMenuInfo = true;
  public showMenuLive = false;

  constructor() {}

  ngOnInit(): void {
    this.novelty = {};
  }

  selctedMenu(opt) {
    // this.showMenu = opt;
    // if (opt == 1) {
    //   this.showMenuInfo = true;
    //   this.showMenuLive = false;
    // } else if (opt == 2){
    //   this.showMenuInfo = false
    //   this.showMenuLive = true;
    // }
  }
}
