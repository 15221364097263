import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import 'rxjs/add/operator/filter';
import { NavbarwwcComponent } from 'app/shared/navbarwwc/navbarwwc/navbarwwc.component';

declare var $: any;

@Component({
    selector: 'app-layout',
    templateUrl: './modules-layout.component.html'
})

export class ModulesLayoutComponent implements OnInit {
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
    url: any;
    location: Location;
    private _router: Subscription;
    // url: string;

    @ViewChild('sidebar') sidebar;
    @ViewChild(NavbarwwcComponent) navbar: NavbarwwcComponent;
    constructor( 
        private router: Router,
        location:Location ) {
        this.location = location;
      }

      ngOnInit() {
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe(event => {
        });
    }
    onActivate(event) {
        window.scroll(0,0);
        //or document.body.scrollTop = 0;
        //or document.querySelector('body').scrollTo(0,0)
    }
}
