import { Component, OnInit, Sanitizer } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NoveltyService } from "app/services/novelty/novelty.service";
import {
  DomSanitizer,
  SafeUrl,
  SafeResourceUrl,
} from "@angular/platform-browser";
import { DatesService } from "app/services/dates/dates.service";
import { AuthService } from "app/services/auth/auth/auth.service";
import { DenunciaComentario } from "app/interfaces/denunciaComentario";
import { DenunciaService } from "app/services/denuncia/denuncia.service";
import swal from "sweetalert2";
import { ubicationUserService } from "app/services/ubicationUser/ubicationUser.service";
declare var $: any;
@Component({
  selector: "app-enventos",
  templateUrl: "./enventos.component.html",
  styleUrls: ["./enventos.component.css"],
})
export class EnventosComponent implements OnInit {
  public t: string =
    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismodLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod ";
  public novelty: Novelty;
  public showMenu = 1;
  public showMenuInfo = true;
  public showMenuLive = false;
  public url: SafeResourceUrl;
  public commentary: Commentary;
  public arrayComments: Commentary[];
  public infoUser: Users;
  public user: Users;
  public isEvent = false;
  public arrayNoveltys: Array<Novelty> = [];
  public usersUbication: Array<Users> = [];
  public arrayNoveltysTop: Array<Novelty> = [];
  public arrayShowAllNovelty: Array<Boolean> = [];
  public eventShowId: String;
  public currentDate: string;
  public isEventModule = true;
  public isEventAll = false;
  public limit: number;
  public denuncia: DenunciaComentario;
  public hiddenButton: boolean = false;

  constructor(
    public noveltyService: NoveltyService,
    private activatedRoute: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private dateServices: DatesService,
    private authService: AuthService,
    private denunciaService: DenunciaService,
    public ubicationService: ubicationUserService
  ) {}

  public novelty_id = this.activatedRoute.snapshot.params.id_event;

  ngOnInit(): void {
    this.limit = 3;
    this.infoUser = JSON.parse(localStorage.getItem("infoUser"));
    this.currentDate = this.dateServices.getDateCurrent();
    console.log("probar eventos",this.novelty_id  )
    this.commentary = {};
    this.authService.verifyUser();
    // if (this.novelty_id == "alls") {
    //   //console.log('*** SCROLL ***');
    //   this.isEvent = true;
    //   this.getEvent();
    //   this.getComment();
    //   this.isEventAll = true;
    // } else
    //console.log(this.novelty_id);

    if (this.novelty_id != "all" && this.novelty_id != "alls") {
      this.isEvent = true;
      this.getEvent();
      this.getComment();
      this.getUsersUbication();
    } else {
      this.isEventAll = true;
    }
    if (this.infoUser) {
      this.arrayComments = [];
      this.commentary = {};
      this.denuncia = {};
      this.novelty = {
        novelty_link: "",
      };
      this.url = this.sanitizerUrl(this.novelty.novelty_link);
      this.getNoveltysListTop();
      this.getNoveltysList();
    } else if (this.infoUser == null || this.infoUser == undefined) {
      this.arrayComments = [];
      this.commentary = {};
      this.denuncia = {};
      this.novelty = {
        novelty_link: "",
      };
      this.url = this.sanitizerUrl(this.novelty.novelty_link);
      this.getNoveltysListTop();
      this.getNoveltysList();
    }
  }

  /**
   * *** Si se ingresa directmente a un evento (this.novelty_id != 'all') ***
   * *** obtenemos toda la data del evento seleccionado ***
   */
  async getEvent() {
    (await this.noveltyService.getNoveltyById(this.novelty_id)).subscribe(
      (categorySnapshot) => {
    console.log("probar eventos")
        this.novelty = categorySnapshot.payload.data() as Novelty;
        console.log("evento");
        //console.log('*** ya temino la carga ***');
        //console.log('*** this.novelty_id ***');
        //console.log(this.novelty_id);

        if (this.novelty.novelty_link.includes("watch?v=")) {
          this.novelty.novelty_link = this.novelty.novelty_link.replace(
            "watch?v=",
            "embed/"
          );
        } else {
        }
        this.url = this.sanitizerUrl(this.novelty.novelty_link);
        this.isEvent = true;
        this.UserRegisterUbication();
      }
    );
  }

  /**
   * *** Si se ingresa directmente a un evento (this.novelty_id != 'all') ***
   * *** obtenemos los comentarios del evento seleccionado ***
   */
  async getComment() {
    (await this.noveltyService.getComment(this.novelty_id)).subscribe(
      (commentarySnapshot) => {
        this.arrayComments = [];
        commentarySnapshot.forEach((commentary: any) => {
          this.arrayComments.push(commentary.payload.doc.data());
        });
      }
    );
  }
  async getUsersUbication() {
    console.log("load ubication", this.novelty_id);
    await this.ubicationService
      .getUsersUbicationList2(this.novelty_id)
      .subscribe((commentarySnapshot) => {
        this.usersUbication = [];
        commentarySnapshot.forEach((commentary: any) => {
          console.log("log", commentary);
          this.usersUbication.push(commentary.payload.doc.data());
        });
      });
  }

  /**
   * * *** aplicamos el metodo bypassSecurityTrustResourceUrl ***
   * *** para poder reproducir los videos (url segura) ***
   * @param novelty_link
   */
  sanitizerUrl(novelty_link: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(novelty_link);
  }

  /**
   * *** Guardamos los comentarios ***
   */
  async saveComent() {
    var commentary_id = new Date().getTime();
    this.commentary.commentary_id = commentary_id.toString();
    this.commentary.commentary_time_register =
      this.dateServices.getTimeCurrent();
    this.commentary.commentary_date_register =
      this.dateServices.getDateCurrent();
    this.commentary.commentary_user = this.infoUser.user_name;
    this.commentary.commentary_state = true;
    this.commentary.commentary_num_like = 0;
    this.commentary.commentary_i_do_not_like = 0;
    this.commentary.commentary_denuncia = false;
    await this.noveltyService.saveComentary(this.commentary, this.novelty_id);
    this.commentary = {};
  }

  /**
   * *** Guardamos la denuncia del comentario ***
   * @param commentary
   */
  async addDenuncia(commentary: Commentary) {
    swal({
      title: "Alerta",
      text: "¿Está seguro que desea denunciar el comentario?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn btn-fill btn-success btn-mr-5",
      cancelButtonClass: "btn btn-fill btn-danger",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sí, denunciar!",
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        if (commentary.commentary_denuncia == false) {
          commentary.commentary_denuncia = true;
          await this.noveltyService
            .updateComentary(commentary, this.novelty_id)
            .then(() => {
              this.saveDenuncia(commentary);
            });
        } else {
          this.saveDenuncia(commentary);
        }
      }
    });
  }

  /**
   * ***  ***
   * @param commentary
   */
  async saveDenuncia(commentary: Commentary) {
    const id_Denuncia = this.dateServices.getTimeCurrent();
    this.denuncia = {
      denunciaC_uid: id_Denuncia,
      denunciaC_denuncia: commentary.commentary_id,
      denunciaC_date: this.dateServices.getTimeCurrent(),
      denunciaC_time: this.dateServices.getDateCurrent(),
    };
    this.denunciaService.saveDenunciaComentario(
      this.denuncia,
      commentary,
      this.novelty,
      this.infoUser
    );
    swal("Gracias!", "Tu opinión es importante.", "success");
  }

  /**
   * *** aumenta los megusta ***
   * @param commentary
   */
  async addLike(commentary: Commentary) {
    commentary.commentary_num_like = commentary.commentary_num_like + 1;
    await this.noveltyService.updateComentary(commentary, this.novelty_id);
  }

  /**
   * *** aumentamos el num de no me gusta ***
   * @param commentary
   */
  async addNotLike(commentary: Commentary) {
    commentary.commentary_i_do_not_like =
      commentary.commentary_i_do_not_like + 1;
    await this.noveltyService.updateComentary(commentary, this.novelty_id);
  }

  /**
   * *** Obtenemos todos los eventos ***
   * *** si se accedes desde ver todos, seteamos un evento por defecto ***
   * *** que seria el primer evento que llegue en la consulta ***
   * *** es decir el ultimo evento creado por los administradores ***
   */
  public async getNoveltysList() {
    // this.noveltyService.getNoveltysList(this.limit).subscribe((noveltys) => {
    //   this.arrayNoveltys = noveltys;
    //   if(this.arrayNoveltys.length<this.limit)
    //   {
    //     this.hiddenButton=true;
    //   }
    //   if (this.novelty_id == "all" || this.novelty_id == "alls") {
    //     if (this.novelty_id == "alls") {
    //       //console.log('*** SCROLL ***');
    //       ;
    //       $('html, body').animate({
    //         scrollTop: $('#community').offset().top
    //       }, 800, function(){
    //       });
    //     }
    //     this.isEventAll = true;
    //     this.novelty_id = this.arrayNoveltys[0].novelty_id;
    //     this.getEvent();
    //     this.getComment();
    //   }
    // });
    await this.noveltyService.getNovelty().subscribe((noveltySnapshot) => {
      this.arrayNoveltys = [];
      noveltySnapshot.forEach((noveltyData) => {
        this.arrayNoveltys.push(noveltyData.payload.doc.data());
      });
    });
  }

  public getNoveltysListTop() {
    this.noveltyService.getNoveltysListTop(this.limit).subscribe((noveltys) => {
      this.arrayNoveltysTop = noveltys;
      if (this.novelty_id == "all" || this.novelty_id == "alls") {
        if (this.arrayNoveltysTop.length > 0) {
          this.isEventAll = true;
          this.novelty_id = this.arrayNoveltysTop[0].novelty_id;
          this.getEvent();
          this.getComment();
        }
      }
    });
  }

  /**
   * *** Ver mas eventos, aumentamos el valor del limite ***
   * *** en tres y consultamos la DB para obtener tres eventos mas ***
   */
  public viewMore() {
    swal({
      title: "Cargando mas eventos...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 2000,
      showConfirmButton: false,
      onOpen: () => {
        swal.showLoading();
      },
    });
    this.limit = this.limit + 3;
    this.arrayNoveltys = [];
    this.noveltyService.getNoveltysList(this.limit).subscribe((novelties) => {
      this.arrayNoveltys = novelties;
      if (this.arrayNoveltys.length < this.limit) {
        this.hiddenButton = true;
      }
    });
  }

  /**
   * *** este metodo permite consultar aplicando limite ***
   * *** desde el ultimo elemento hasta el limite que se le pase ***
   * *** no es estable, hay que revisarlo ***
   */
  public viewMores() {
    this.noveltyService.getNoveltysListViewMoreTree().subscribe((novelties) => {
      novelties.forEach((novelty) => {
        novelty = novelty as Novelty;
        if (this.arrayNoveltys.includes(novelty)) {
          return;
        } else {
          this.arrayNoveltys.push(novelty);
        }
      });
    });
  }

  /**
   * *** Seteamos el evento seleccionado ***
   * @param novelty
   */
  public viewEvent(novelty: Novelty) {
    this.novelty_id = novelty.novelty_id;
    this.getEvent();
    this.getComment();
    this.isEvent = true;
    $("html, body").animate(
      {
        scrollTop: $("#section-en-vivo").offset().top,
      },
      800,
      function () {}
    );
  }

  public toggleContent(i) {
    this.arrayShowAllNovelty[i] = true;
  }

  public toggleContentHiden(i) {
    this.arrayShowAllNovelty[i] = false;
  }

  /**
   * *** Limpiamos la data ***
   */
  cancel() {
    this.denuncia = {};
  }
  public UserRegisterUbication() {
    this.infoUser = JSON.parse(localStorage.getItem("infoUser"));
    this.infoUser.user_register_date = this.dateServices.getDateCurrent();
    this.infoUser.user_register_time = this.dateServices.getTimeCurrent();
    this.ubicationService.createUbication2(this.novelty, this.infoUser);
  }
}
