import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private fb: AngularFirestore

  ) { }


  //Crea una nueva producto
  public createProduct(product: Product, infoUser: Users) {
    return this.fb.collection('users').doc(infoUser.user_email).collection('products').doc(product.product_id).set(product);
  }
  //Obtiene una nueva producto
  public getProduct(documentId: string) {
    return this.fb.collection('users').doc(documentId).snapshotChanges();
  }
  public getProductById(product: any)
  {
   // console.log(product);
    return this.fb.collection('users').doc(product.user_id).collection('products').doc(product.product_id).valueChanges();
  }
  
  //Obtiene todos los productos
  public getProducts(infoUser: Users) {
    return this.fb.collection('users').doc(infoUser.user_email).collection('products').snapshotChanges();
    // return this.fb.collection('users').doc(infoUser.user_email).collection('products').valueChanges();
  }
  public getProductsAll(infoUser: Users) {
    return this.fb.collection('users').doc(infoUser.user_email).collection('products').snapshotChanges();
    // return this.fb.collection('users').doc(infoUser.user_email).collection('products').valueChanges();
  }
  //Actualiza una nueva producto
  public updateProduct(product: Product,  infoUser: Users) {
    return this.fb.collection('users').doc(infoUser.user_email).collection('products').doc(product.product_id).update(product)
  }
  //Elimina una nueva producto
  public deleteProduct(documentId: string, infoUser: Users) {
     this.fb.collection('users').doc(infoUser.user_email).collection('products').doc(documentId).delete();
  }



}
