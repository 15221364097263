import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { SidebarModule } from "./sidebar/sidebar.module";
import { FixedPluginModule } from "./shared/fixedplugin/fixedplugin.module";
import { FooterModule } from "./shared/footer/footer.module";
import { NavbarModule } from "./shared/navbar/navbar.module";
import { PagesnavbarModule } from "./shared/pagesnavbar/pagesnavbar.module";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { AppRoutes } from "./app.routing";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "environments/environment";
import { AuthService } from "./services/auth/auth/auth.service";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { MatSliderModule } from "@angular/material/slider";
import { IndexComponent } from "./index/index.component";
import { Ng2CarouselamosModule } from "ng2-carouselamos";
import { UiCarouselModule } from "ngx-ui-carousel";
import { ModalLoginComponent } from "./index/modal-login/modal-login/modal-login.component";
import { NavbarwwcComponent } from "./shared/navbarwwc/navbarwwc/navbarwwc.component";
import { NavbarindexComponent } from "./shared/navbarindex/navbarindex/navbarindex.component";
import { ModulesLayoutComponent } from "./layouts/modules/modules-layout.component";
import { HttpModule } from "@angular/http";
import { PerfilComponent } from './modules/perfil/perfil/perfil.component'
import { EnventosComponent } from "./modules/eventos/enventos/enventos.component";
import { ChatComponent } from "./modules/eventos/chat/chat.component";
import { FooterbbcComponent } from './shared/footerbbc/footerbbc.component';
import { ListPublicationsComponent } from './modules/listPublications/list-publications/list-publications.component';
import { WishlistComponent } from './modules/wishlist/wishlist.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ChartsModule } from "ng2-charts";
import { BrowserModule, Meta } from '@angular/platform-browser';

// import { ChartsModule } from 'ng2-charts';
// import { ChartsModule } from 'ng2-charts/ng2-charts';

/**
 * *** OJO si no reconoce los comandos de angular se debe declarar aqui ***
 */


@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
    }),
    HttpModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    FixedPluginModule,
    PagesnavbarModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireStorageModule,
    MatSliderModule,
    Ng2CarouselamosModule,
    UiCarouselModule,
    ChartsModule,

  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ModulesLayoutComponent,
    IndexComponent,
    ModalLoginComponent,
    NavbarwwcComponent,
    NavbarindexComponent,
    PerfilComponent,
    EnventosComponent,
    ChatComponent,
    FooterbbcComponent,
    ListPublicationsComponent,
    WishlistComponent,
  ],
  bootstrap: [AppComponent],
  providers: [AuthService, Meta],
  
})
export class AppModule {}
