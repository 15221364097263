import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { User, auth } from "firebase";
import { first } from "rxjs/operators";
import * as firebase from "firebase/app";
import swal from "sweetalert2";
import { Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { take } from "rxjs/operators";
import { DatesService } from "app/services/dates/dates.service";
import { RegisterService } from "app/services/register/register.service";
declare var $: any;

@Injectable()
export class AuthService {
  public user: User;
  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private fb: AngularFirestore,
    private dateServices: DatesService,
    private registerService: RegisterService
  ) {}

  /**
   * *** Login con firebase ***
   */
  public loginFacebookUser() {
    return this.afAuth
      .signInWithPopup(new auth.FacebookAuthProvider())
      .then((credential) =>
        this.updateUserData(
          credential.user.providerData,
          credential.user,
          "",
          1
        )
      )
      .catch((error) => {
        console.error("Error en login con Facebook:", error);
        // Aquí puedes mostrar un mensaje de error al usuario
      });
  }

  /**
   * *** Login con Google ***
   */
  public loginGoogleUser() {
    return this.afAuth
      .signInWithPopup(new auth.GoogleAuthProvider())
      .then((credential) =>
        this.updateInfoUser(
          credential,
          credential.additionalUserInfo.profile,
          "",
          1
        )
      );
  }

  /**
   * *** Metodo para loguearse o registrarse con facebook/google ***
   * *** Si el usuario existe se loguea, si no existe se registra ***
   * @param credential
   * @param user
   * @param route
   * @param opt
   */
  public async updateInfoUser(credential, user, route, opt) {
    console.log("updateInfoUser");
    /**
     * *** consultamos si el usuario existe en la BD ***
     */
    var responseUser;

    if (opt == 1) {
      responseUser = (await this.getUserByEmail(user.email))
        .pipe(take(1))
        .toPromise();
    } else {
      responseUser = (await this.getUserByEmail(user.user_email))
        .pipe(take(1))
        .toPromise();
    }
    console.log(responseUser);
    $("#modalLogin").modal("hide");
    let usuario = responseUser;
    console.log("ujsuario", usuario);
    if (await responseUser) {
      if ((await responseUser)["user_state_validation"]) {
        var infoUser = {
          user_email: (await responseUser)["user_email"],
          user_web: (await responseUser)["user_web"],
          user_position_premium: 1,
          user_country: (await responseUser)["user_country"],
          user_lastname: (await responseUser)["user_lastname"],
          user_name: (await responseUser)["user_name"],
          user_type_account: (await responseUser)["user_type_account"],
          user_state_account: (await responseUser)["user_state_account"],
          user_company_id: (await responseUser)["user_company_id"],
          user_id: (await responseUser)["user_id"],
          user_image_perfil: (await responseUser)["user_image_perfil"],
          user_url_logo: (await responseUser)["user_url_logo"],
          user_company_name: (await responseUser)["user_company_name"],
          user_id_stand: (await responseUser)["user_id_stand"],
        };

        localStorage.setItem("infoUser", JSON.stringify(infoUser));
        if (route == "") {
          if ((await responseUser)["user_type_account"] == 0) {
            /// *** Admin ***
            this.router.navigate(["/categories"]);
          }
          if ((await responseUser)["user_type_account"] == 1) {
            /// *** Proveedor / vefificamos si ya validó el codigo ***
            if ((await responseUser)["user_state_validation"]) {
              this.router.navigate(["/home"]);
            } else {
              swal("Atención", "Estamos validando su información", "success");
            }
          } else if ((await responseUser)["user_type_account"] == 2) {
            /// *** Cliente ***
            this.router.navigate(["/perfil"]);
          }
        } else {
          this.router.navigate([route]);
        }
        return user;
      } else {
        this.router.navigate(["/pages/register/" + user.email]);
      }
    } else {
      var userRegister: Users;
      userRegister = {
        user_name: user.given_name,
        user_lastname: user.family_name,
        user_email: user.email,
        user_position_premium: 1,
        user_image_perfil: user.picture,
        user_providerId: user.id,
        user_state_account: true,
        user_register_stepOne: true,
        user_register_stepTwo: true,
        user_register_date: this.dateServices.getDateCurrent(),
        user_register_time: this.dateServices.getTimeCurrent(),
        user_state_validation: false,
        user_image_stand: "",
        array_ids_categorys: ["***"],
        user_categorys: [
          {
            nombre: "***",
            url: "***",
            id: "***",
            description: "***",
          },
        ],
        user_type_account: 2,
        user_type_account_name: "usuario",
        user_company_id: "",
        is_premium: false,
      };
      $("#modalLogin").modal("hide");
      await this.registerService.registerUser(userRegister);
      this.router.navigate(["/pages/register/" + user.email]);
    }
  }

  /**
   * *** Metodo para loguearse o registrarse con facebook/google ***
   * *** Si el usuario existe se loguea, si no existe se registra ***
   * @param user
   * @param credential
   * @param route
   * @param opt
   */
  async updateUserData(user, credential, route, opt) {
    console.log(user, credential, route, opt);
    var responseUser;
    /**
     * *** consultamos si el usuario existe en la BD ***
     */
    if (opt == 1) {
      responseUser = (await this.getUserByEmail(user[0].email))
        .pipe(take(1))
        .toPromise();
    } else {
      responseUser = (await this.getUserByEmail(user.user_email))
        .pipe(take(1))
        .toPromise();
    }
    $("#modalLogin").modal("hide");

    if (await responseUser) {
      if ((await responseUser)["user_state_validation"]) {
        var infoUser = {
          user_email: (await responseUser)["user_email"],
          user_web: (await responseUser)["user_web"],
          user_country: (await responseUser)["user_country"],
          user_lastname: (await responseUser)["user_lastname"],
          user_name: (await responseUser)["user_name"],
          user_type_account: (await responseUser)["user_type_account"],
          user_state_account: (await responseUser)["user_state_account"],
          user_company_id: (await responseUser)["user_company_id"],
          user_id: (await responseUser)["user_id"],
          user_image_perfil: (await responseUser)["user_image_perfil"],
          user_url_logo: (await responseUser)["user_url_logo"],
          user_company_name: (await responseUser)["user_company_name"],
          user_id_stand: (await responseUser)["user_id_stand"],
        };

        localStorage.setItem("infoUser", JSON.stringify(infoUser));

        if (route == "") {
          if ((await responseUser)["user_type_account"] == 0) {
            /// *** Admin ***
            this.router.navigate(["/categories"]);
          }
          if ((await responseUser)["user_type_account"] == 1) {
            /// *** Proveedor / vefificamos si ya validó el codigo ***
            if ((await responseUser)["user_state_validation"]) {
              this.router.navigate(["/home"]);
            } else {
              swal("Atención", "Estamos validando su información", "success");
            }
          } else if ((await responseUser)["user_type_account"] == 2) {
            /// *** Cliente ***
            this.router.navigate(["/perfil"]);
          }
        } else {
          this.router.navigate([route]);
        }
        return user;
      } else {
        if (opt == 1) {
          this.router.navigate(["/pages/register/" + user[0].email]);
        }
        if (opt == 3) {
          console.log("no redireccionar");
        } else {
          this.router.navigate(["/pages/register/" + user.user_email]);
        }
      }
    } else {
      /// *** si no existe el usuario lo creamos ***
      var userRegister: Users;
      userRegister = {
        user_name: user[0].displayName,
        user_email: user[0].email,
        user_uid: user[0].uid,
        user_position_premium: 1,
        user_image_perfil: user[0].photoURL,
        user_providerId: user[0].providerId,
        user_state_account: true,
        user_register_stepOne: true,
        user_register_stepTwo: true,
        user_register_date: this.dateServices.getDateCurrent(),
        user_register_time: this.dateServices.getTimeCurrent(),
        user_state_validation: false,
        user_image_stand: "",
        array_ids_categorys: ["***"],
        user_categorys: [
          {
            nombre: "***",
            url: "***",
            id: "***",
            description: "***",
          },
        ],
        user_register_stepSix: true,
        user_type_account: 2,
        user_type_account_name: "usuario",
        user_company_id: "",
        is_premium: false,
      };

      await this.registerService.registerUser(userRegister);
      if (opt != 3) this.router.navigate(["/pages/register/" + user[0].email]);
    }
  }

  /**
   * *** Metodo para detectar el estado de autenticacion del usuario ***
   *
   */
  public async verifyUser() {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }

  /**
   * *** Devuelve la informacion de un usuario ***
   * @param uid
   */
  async getUserByEmail(uid: string) {
    console.log("getuserbyemail", uid);
    return this.fb.collection("users").doc(uid).valueChanges();
  }
  // Método para actualizar el estado de conexión del usuario
  async updateUserStatus(uid: string, isActive: boolean): Promise<void> {
    await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .set({ isActive: isActive, lastLogin: new Date() }, { merge: true });
  }
  /**
   * *** Login del usuario recibe la ruta para determinar la ***
   * *** Navegacion inicial del usuario ***
   * @param email
   * @param password
   * @param route
   */
  async login(email: string, password: string, route: any) {
    try {
      console.log("vamos a ejecutar");

      const result = await this.afAuth.signInWithEmailAndPassword(
        email,
        password
      );
      $("#modalLogin").modal("hide");

      var responseUser = (
        await this.getUserByEmail(result.user.email?.toLowerCase())
      )
        .pipe(take(1))
        .toPromise();
      console.log("respuesta", result);
      console.log("amtes del response user", responseUser);
      if (responseUser) {
        console.log("respuesta despues", responseUser);
        let respuestaUser = await responseUser;
        console.log("respuestaUser", respuestaUser);
        var infoUser = {
          user_email: (await responseUser)["user_email"],
          user_state_validation: (await responseUser)["user_state_validation"],
          user_web: (await responseUser)["user_web"],
          user_country: (await responseUser)["user_country"],
          user_lastname: (await responseUser)["user_lastname"],
          user_name: (await responseUser)["user_name"],
          user_type_account: (await responseUser)["user_type_account"],
          user_state_account: (await responseUser)["user_state_account"],
          user_company_id: (await responseUser)["user_company_id"],
          user_id: (await responseUser)["user_id"],
          user_image_perfil: (await responseUser)["user_image_perfil"],
          user_url_logo: (await responseUser)["user_url_logo"],
          user_company_name: (await responseUser)["user_company_name"],
          user_id_stand: (await responseUser)["user_id_stand"],
        };
        console.log(infoUser);

        if (
          !(await responseUser)["user_state_validation"] &&
          (await responseUser)["user_type_account"] == 2
        ) {
          swal(
            "Atención",
            "Su cuenta aún no ha sido activada. Por favor, espere a la activación.",
            "error"
          );
          return;
        }
        localStorage.setItem("infoUser", JSON.stringify(infoUser));
        if (route === false) {
          return;
        } else if (route === "") {
          // firebase
          //   .auth()
          //   .signInWithEmailAndPassword(email, password)
          //   .then((userCredential) => {
          //     const uid = userCredential.user.uid;
          //     // Agregar UID a la colección de usuarios activos
          //     firebase.firestore().collection("activeUsers").doc(uid).set({
          //       active: true,
          //       lastActive: firebase.firestore.FieldValue.serverTimestamp(),
          //     });
          //   });
          const uid = infoUser.user_email;
          if (uid) {
            // Marcar al usuario como activo
            await this.updateUserStatus(infoUser.user_email, true);
          }
          if ((await responseUser)["user_type_account"] == 0) {
            /// *** Admin ***
            this.router.navigate(["/categories"]);
          }
          if ((await responseUser)["user_type_account"] == 1) {
            /// *** Proveedor / vefificamos si ya validó el codigo ***
            if ((await responseUser)["user_state_validation"]) {
              this.router.navigate(["/home"]);
            } else {
              swal("Atención", "Estamos validando su información", "success");
            }
          } else if ((await responseUser)["user_type_account"] == 2) {
            /// *** Cliente ***
            this.router.navigate(["/perfil"]);
          }
        } else {
          this.router.navigate([route]);
        }
        return result;
      }
    } catch (error) {
      console.log(error.code);
      $("#modalLogin").modal("hide");
      if (error.code == "auth/wrong-password") {
        swal(
          "Atención",
          "La contraseña no es válida o el usuario no tiene una contraseña",
          "error"
        );
      }
      if (error.code == "auth/user-not-found") {
        swal(
          "Atención",
          "No hay registro de usuario correspondiente a este email. El usuario puede haber sido eliminado",
          "error"
        );
      }
      if (error.code == "auth/invalid-email") {
        swal("Atención", "El email no tiene un formato válido.", "error");
      }
      if (error.code == "auth/too-many-requests") {
        swal(
          "Atención",
          "Demasiados intentos de inicio de sesión fallidos.",
          "error"
        );
      }
    }
  }

  async loginDeleteStand(email: string, password: string) {
    console.log("INGRESO A METODO");

    try {
      const result = await this.afAuth.signInWithEmailAndPassword(
        email,
        password
      );
      console.log(result);
      result.user
        .delete()
        .then(function () {
          console.log("eliminado");
        })
        .catch(function (error) {
          console.log("No eliminado");
        });
      // var user = firebase.auth().currentUser;
    } catch (error) {}
  }

  async register(email: string, password: string) {
    try {
      const result = await this.afAuth
        .createUserWithEmailAndPassword(email, password)
        .then((ok) => {
          return ok.user;
        })
        .catch((error) => {
          if (error.code == "auth/user-not-found") {
            swal(
              "Atención",
              "No hay registro de usuario correspondiente a este email. El usuario puede haber sido eliminado",
              "error"
            );
          }
          if (error.code == "auth/email-already-in-use") {
            swal("Atención", "El email ingresado ya está en uso", "error");
          }
          if (error.code == "auth/wrong-password") {
            swal(
              "Atención",
              "La contraseña no es válida o el usuario no tiene una contraseña",
              "error"
            );
          }
          if (error.code == "auth/too-many-requests") {
            swal(
              "Atención",
              "Demasiados intentos de inicio de sesión fallidos.",
              "error"
            );
          }
          if (error.code == "auth/invalid-email") {
            swal("Atención", "El email no tiene un formato válido.", "error");
          }
        });
      return result;
    } catch (error) {
      if (error.code == "auth/user-not-found") {
        swal(
          "Atención",
          "No hay registro de usuario correspondiente a este email. El usuario puede haber sido eliminado",
          "error"
        );
      }
      if (error.code == "auth/email-already-in-use") {
        swal("Atención", "El email ingresado ya está en uso", "error");
      }
      if (error.code == "auth/wrong-password") {
        swal(
          "Atención",
          "La contraseña no es válida o el usuario no tiene una contraseña",
          "error"
        );
      }
      if (error.code == "auth/too-many-requests") {
        swal(
          "Atención",
          "Demasiados intentos de inicio de sesión fallidos.",
          "error"
        );
      }
      if (error.code == "auth/invalid-email") {
        swal("Atención", "El email no tiene un formato válido.", "error");
      }
      return error;
    }
  }

  /**
   * *** Método para cerrar sesión ***
   */
  async logout() {
    ////console.log('*** logout ***');
    localStorage.clear();
    // console.log("logout", (await this.afAuth.currentUser).email);
    const uid = (await this.afAuth.currentUser)?.email;
    if (uid) {
      await this.updateUserStatus(uid, false); // Marcar como inactivo
    }
    await this.afAuth.signOut();
    /*
    
      */
    this.router.navigate(["/"]);
  }

  getCurrentUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  async sendEmailRecoverPass(user: any) {
    /// *** validar que el email exista para enviar el correo ***
    var responseUser = (await this.getUserByEmail(user.email))
      .pipe(take(1))
      .toPromise();

    if (await responseUser) {
    }

    let code = new Date().getTime().toString().substring(7);
    var infoEmeil = {
      user_email: user.email,
    };
    await this.fb
      .collection("emails/recover_pass/send_email")
      .doc(user.email)
      .set(infoEmeil);
  }
}
