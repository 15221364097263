import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ubicationUserService {

  constructor(

    private ub: AngularFirestore
  ) { }

  //Crea un nueva ubicacion
  public createUbication(company: Users, infoUser: Users) {
    const current = new Date();
    const timestamp = current.getTime();
    this.ub.collection('ubicationUser').doc(`${company.user_company_name}`).collection('users').doc(timestamp.toString()).set(infoUser);
  }
  
 
  public getUsersUbicationList(company: Users) {
    return this.ub.collection('ubicationUser').doc(`${company.user_company_name}`).collection<Users>("users", ref => ref.orderBy('user_register_date', 'desc')).valueChanges();
  }

  public createUbication2(nov: Novelty, infoUser: Users) {
    const current = new Date();
    const timestamp = current.getTime();
    this.ub.collection('ubicationUserNovelty').doc(`${nov.novelty_id}`).collection('users').doc(timestamp.toString()).set(infoUser);
  }


  public getUsersUbicationList2(nove: any) {
    return this.ub.collection('ubicationUserNovelty').doc(`${nove.novelty_name}`).collection<Users>("users", ref => ref.orderBy('user_register_date', 'desc')).valueChanges();
  }


  public createUbicationTotal(infoUser: Users) {
    const current = new Date();
    const timestamp = current.getTime();
    this.ub.collection('ubicationUserNoveltyTotal').doc('usersTotal').collection('users').doc(timestamp.toString()).set(infoUser);
  }
  
  public getUbicationTotal() {
    return this.ub.collection('ubicationUserNoveltyTotal').doc('usersTotal').collection<Users>("users", ref => ref.orderBy('user_register_date', 'desc')).valueChanges();
  }


  //Obtiene un torta
  public getTorta(documentId: string) {
    return this.ub.collection('ubicationUser').doc(documentId).snapshotChanges();
  }
  //Obtiene todos las ub
  public getTortas() {
    return this.ub.collection('ubicationUser').snapshotChanges();
  }
  //Actualiza una torta
  public updateTortas(documentId: string, data: any) {
    return this.ub.collection('ubicationUser').doc(documentId).set(data);
  }

  public deleteTorta(documentId: string) {
    return this.ub.collection('ubicationUser').doc(documentId).delete();
  }
}

