import { Component, OnInit, HostListener } from "@angular/core";
import swal from "sweetalert2";
import * as firebase from "firebase/app";
import { Router } from "@angular/router";
import { ImageCroppedEvent } from "ngx-image-cropper";
/** Services */
import { StorageService } from "app/services/storage/storage.service";
import { CategoryService } from "app/services/categorias/categorias.service";
import { UsersService } from "app/services/users/users.service";
import { AuthService } from "app/services/auth/auth/auth.service";
import { NotificationsService } from "app/services/notifications/notifications.service";
import { ActivatedRoute } from "@angular/router";
/** INTERFACES */
import { Notifications } from "app/interfaces/notifications";
import { NumberValueAccessor } from "@angular/forms";
import { take } from "rxjs/operators";
import { cssNumber } from "jquery";
declare var $: any;
@Component({
  selector: "app-perfil",
  templateUrl: "./perfil.component.html",
  styleUrls: ["./perfil.component.css"],
})
export class PerfilComponent implements OnInit {
  name = "Angular";
  imageChangedEvent: any = "";
  croppedImage: any = "";

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  public isUserListVisible = false; // Variable para controlar la visibilidad de la lista de usuarios

  public infoUser: Users;
  public user: Users;
  public category: Category;
  public arrayCategory: any[];
  public imageFile: any;
  public arrayCountries: any[];
  public arrayUsersLogined: any[];
  public imageSrc: any;
  public arrayNotificationsSave: Array<Notifications> = [];
  public arrayNotificationsInfo: Array<Notifications> = [];
  public isContentToggled: Boolean;
  public arrayShowAllNotification: Array<Boolean> = [];
  public isEditName: Boolean = false;
  public canEditUser: Boolean = true;
  public arrayAddCategorys: Array<any> = [];
  public categorySelect: Category;
  public isViewCommunity = true;
  public email = "";
  public isEventModule = false;
  constructor(
    private categoryService: CategoryService,
    private usersService: UsersService,
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService,
    private notificationService: NotificationsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isViewCommunity = true;
    this.isEventModule = false;
    this.arrayNotificationsInfo = [];
    this.arrayNotificationsSave = [];
    this.route.params.subscribe((params) => {
      if (params["email"]) {
        this.email = decodeURIComponent(params["email"]);
        this.getUser(this.email);
        this.canEditUser = false;
      }
      console.log("imprime email", this.email);
    });
    this.user = {};
    if (this.email == "") {
      this.infoUser = JSON.parse(localStorage.getItem("infoUser"));
      if (this.infoUser && this.infoUser.user_type_account == 2) {
        this.authService.verifyUser();

        this.getUsers();
        this.getCategories();
        if (this.infoUser.user_type_account == 2) {
          this.user = {
            user_url_logo: "",
            user_email: this.infoUser.user_email,
            user_name: this.infoUser.user_name,
            user_company_name: this.infoUser.user_company_name,
            user_lastname: this.infoUser.user_lastname,
            user_categorys: this.infoUser.user_categorys,
            user_image_perfil: this.infoUser.user_image_perfil,
          };
        } else {
          this.getUsers();
        }
      } else {
        this.router.navigate([""]);
        return;
      }
    }
  }
  toggleUserList() {
    if (!this.isUserListVisible) this.getUsersRegistered();
    this.isUserListVisible = !this.isUserListVisible; // Alterna la visibilidad de la lista
  }
  @HostListener("document:click", ["$event"])
  onDocumentClick(event: Event) {
    const targetElement = event.target as HTMLElement;

    // Verifica si el clic ocurrió fuera del botón o del listado
    if (!targetElement.closest(".user-menu-container")) {
      this.isUserListVisible = false
    }
  }
  async getUsersRegistered() {
    console.log("vamosa recuperar usuarios");
    (await this.usersService.getUsersRegistered()).subscribe((user) => {
      this.arrayUsersLogined = [];
      user.forEach((sale) => {
        this.arrayUsersLogined.push(sale.payload.doc.data());
        // this.arryaAuxSales.push(sale.payload.doc.data());
        // this.arrayAllSales.push(sale.payload.doc.data());
      });
      this.arrayUsersLogined.sort((a, b) => {
        // Si a es activo y b no, a va antes (return -1)
        if (a.isActive && !b.isActive) return -1;
        // Si a no es activo y b sí, a va después (return 1)
        if (!a.isActive && b.isActive) return 1;
        // Si ambos son iguales en isActive, mantener el orden actual (return 0)
        return 0;
      });
    });
    // let data =
    // console.log(data);
  }
  async getUser(email) {
    (await this.usersService.getUserByEmails(email)).subscribe((user) => {
      this.user = user;
      this.infoUser = user;
      console.log("infouser", this.infoUser);
    });
  }
  /**
   * *** obtenemos la data del usuario ***
   */
  async getUsers() {
    this.user.user_categorys = [];
    this.user.array_ids_categorys = [];
    (
      await this.usersService.getUserByEmails(this.infoUser.user_email)
    ).subscribe((user) => {
      this.user = user;
    });
    this.geNotificationsFromUser();
  }
  public async changeImage(event) {
    const file = event.srcElement.files;
    if (file && file.length > 0) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const fileUpload = e.target.result;
      };
      this.imageSrc = await event.target.files[0];
      if (this.imageSrc) {
        this.user.user_image_perfil = await this.storageService.uploadFile(
          `user/perfil_image/${this.user.user_name}`,
          this.imageSrc
        );
        this.infoUser;

        var infoUser = {
          user_email: this.infoUser.user_email,
          user_country: this.infoUser.user_country,
          user_lastname: this.infoUser.user_lastname,
          user_name: this.infoUser.user_name,
          user_type_account: this.infoUser.user_type_account,
          user_state_account: this.infoUser.user_state_account,
          user_image_perfil: this.user.user_image_perfil,
        };
        localStorage.setItem("infoUser", JSON.stringify(infoUser));

        this.infoUser = JSON.parse(localStorage.getItem("infoUser"));

        this.usersService.updateImagePerfil(
          this.infoUser,
          this.user.user_image_perfil
        );
      }
    }
  }

  public async deleteInterest(interest: any) {
    if (this.user.user_categorys.length === 1) {
      swal("Atención", "Su perfil debe incluir al menos un interés.", "error");
      return;
    } else {
      this.user.user_categorys.forEach((element) => {
        if (element.id === interest.id) {
          const j = this.user.array_ids_categorys.indexOf(interest.id);
          this.user.array_ids_categorys.splice(j, 1);
          const i = this.user.user_categorys.indexOf(element);
          this.user.user_categorys.splice(i, 1);
          this.usersService.deleteInterest(this.user);
        }
      });
    }
  }
  /**
   * *** obtenemos las categorias ***
   */
  async getCategories() {
    await this.categoryService.getCategories().subscribe((categorySnapshot) => {
      this.arrayCategory = [];
      categorySnapshot.forEach((categoryData) => {
        this.arrayCategory.push(categoryData.payload.doc.data());
      });
    });
  }

  saveUser(user: Users, valid: boolean) {
    this.usersService.saveUser(this.user, this.infoUser);
  }

  public async selectedCategory(category: Category) {
    if (this.user.array_ids_categorys !== undefined) {
      if (this.user.array_ids_categorys.includes(category.id)) {
        swal(
          " " + category.description + " ",
          "Ya está agregado a sus intereses",
          "info"
        );

        return;
      } else {
        this.user.user_categorys.push(category);
        this.user.array_ids_categorys.push(category.id);
        this.usersService.updateData(this.user.user_email, this.user);
      }
    } else {
      this.user.user_categorys = [];
      this.user.array_ids_categorys = [];
      this.user.user_categorys.push(category);
      this.user.array_ids_categorys.push(category.id);
      this.usersService.updateData(this.user.user_email, this.user);
    }
  }

  /**
   * EVENTO CARGA DE IMAGEN.
   * @param event.
   */
  public onChangeImage(event) {
    const files = event.srcElement.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        // this.imageSrc = e.target.result;
      };
      reader.readAsDataURL(files[0]);
    }
    this.upload(event);
  }

  public upload(event): void {
    const file = event.target.files[0];
    this.imageFile = file;
    this.uploadDocumentToStorage();
  }

  uploadDocumentToStorage() {
    // let serviceGlobal = this.registerService;
    let infoUserLocal = this.infoUser;
    let categoryLocal = this.category;
    let userLocal = this.user;
    let saveuserLocal = this.usersService;
    var storageService = firebase.storage();
    var refStorage = storageService.ref("/user").child(this.user.user_id);
    var uploadTask = refStorage.put(this.imageFile);
    uploadTask.on(
      "state_changed",
      null,
      function (error) {},
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          userLocal.user_url_logo = downloadURL;
          saveuserLocal.saveUser(userLocal, infoUserLocal);
          swal({
            title: "Muy bien",
            text: "Información logo guardado correctamente",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-fill btn-success",
            type: "success",
          }).catch(swal.noop);
        });
      }
    );
  }

  // logout() {
  //   this.doLogout();
  // };

  // doLogout() {
  //   this.authService.logout();
  //   this.router.navigate(["/"]);
  // }

  public geNotificationsFromUser() {
    this.arrayShowAllNotification = [];
    this.arrayNotificationsInfo = [];
    this.arrayNotificationsSave = [];
    //console.log(this.infoUser.user_email);

    this.usersService
      .getNotificationsFromUsers(this.infoUser.user_email)
      .subscribe((notifications) => {
        this.arrayNotificationsInfo = [];
        this.arrayNotificationsSave = [];
        let count = 0;
        //console.log(notifications);

        notifications.forEach((n) => {
          this.getInfoNotifications(n.notification_id);
          this.arrayShowAllNotification[count] = false;
          count++;
        });
      });
  }

  public getInfoNotifications(notificationsId: String) {
    this.notificationService
      .getNotificationInfoFromNotificationId(notificationsId)
      .subscribe((notification) => {
        console.log(notification);
        if (notification.length > 0) {
          this.arrayNotificationsInfo.push(notification[0]);
        }
      });
  }

  public toggleContent(i) {
    this.arrayShowAllNotification[i] = true;
  }
  public toggleContentHiden(i) {
    this.arrayShowAllNotification[i] = false;
  }

  public saveNotification(notification_id) {
    swal({
      title: "Alerta",
      text: "¿Desea guardar esta notificación?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn btn-fill btn-success btn-mr-5",
      cancelButtonClass: "btn btn-fill btn-danger",
      confirmButtonText: "Sí, guardar!",
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        this.usersService.saveNotification(notification_id, this.infoUser);
        this.arrayNotificationsInfo = [];
        this.upDateStatusNotification1(notification_id);
      }
    });
  }

  public upDateStatusNotification1(notification_id) {
    this.usersService.updateStatusNotification(
      notification_id,
      this.infoUser.user_email
    );
    //console.log("Eliminando..")
    this.arrayNotificationsInfo = [];
    //console.log(this.arrayNotificationsInfo);
  }

  public upDateStatusNotification(notification_id) {
    swal({
      title: "Alerta",
      text: "¿Está seguro que desea eliminar esta notificación?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn btn-fill btn-success btn-mr-5",
      cancelButtonClass: "btn btn-fill btn-danger",
      confirmButtonText: "Sí, eliminar!",
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        this.usersService.updateStatusNotification(
          notification_id,
          this.infoUser.user_email
        );
        this.arrayNotificationsInfo = [];
      }
    });
  }

  public editName() {
    this.isEditName = true;
  }

  public updateName() {
    if (this.user.user_name == "") {
      swal("Atención", "Por favor ingrese su nombre de perfil", "error");
      return;
    } else if (this.user.user_lastname == "") {
      swal("Atención", "Por favor ingrese su apellido de perfil", "error");
      return;
    } else {
      const nameUpdate = {
        user_name: this.user.user_name,
        user_lastname: this.user.user_lastname,
        user_company_name: this.user.user_company_name,
      };
      this.usersService.updateData(this.user.user_email, nameUpdate);
      this.isEditName = false;
    }
  }
}
