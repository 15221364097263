import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "app/services/auth/auth/auth.service";
declare var $: any;

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

export const ROUTESUSER: RouteInfo[] = [
  {
    path: "/home",
    title: "Inicio",
    type: "link",
    icontype: "pe-7s-home",
  },
  {
    path: "/products",
    title: "Mis productos",
    type: "link",
    icontype: "pe-7s-box2",
  },
  {
    path: "/list-post",
    title: "Mis posts",
    type: "link",
    icontype: "pe-7s-box2",
  },
];
export const ROUTESADMIN: RouteInfo[] = [
  {
    path: "/categories",
    title: "Categorías",
    type: "link",
    icontype: "pe-7s-ticket",
  },
  {
    path: "/novelties",
    title: "Novedades",
    type: "link",
    icontype: "pe-7s-info",
  },
  {
    path: "/users",
    title: "Stands",
    type: "link",
    icontype: "pe-7s-culture",
  },

  {
    path: "/countries",
    title: "Paises",
    type: "link",
    icontype: "pe-7s-folder",
  },
  {
    path: "/list-users",
    title: "Usuarios",
    type: "link",
    icontype: "pe-7s-user",
  },
  {
    path: "/notifications",
    title: "Notificaciones",
    type: "link",
    icontype: "pe-7s-bell",
  },
  {
    path: "/message",
    title: "Mensajes",
    type: "link",
    icontype: "pe-7s-mail",
  },
  {
    path: "/denuncias",
    title: "Denuncias",
    type: "link",
    icontype: "pe-7s-mail",
  },
  {
    path: "/list-posts",
    title: "Posts",
    type: "link",
    icontype: "pe-7s-box2",
  },
  {
    path: "/statistics",
    title: "Estadísticas",
    type: "link",
    icontype: "pe-7s-graph2",
  },
  //   {
  //     path: '/charts',
  //     title: 'Charts',
  //     type: 'link',
  //     icontype: 'pe-7s-graph1'

  // }
];

export const ROUTES: RouteInfo[] = [
  {
    path: "/categorias",
    title: "Menu 1",
    type: "link",
    icontype: "pe-7s-graph1",
  },
  {
    path: "/register",
    title: "Menu 2",
    type: "link",
    icontype: "pe-7s-user",
  },
];

@Component({
  moduleId: module.id,
  selector: "sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent {
  public menuItems: any[];
  infoUser: any;

  constructor(private authSvc: AuthService, private router: Router) {}

  isNotMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    var $sidebar = $(".sidebar-wrapper");
    var $bgLogo = $(".bgLogo");
    $sidebar.css(
      "background-image",
      "linear-gradient(to right, #00092A, #002a94, #008BE9 98%)"
    );
    $bgLogo.css(
      "background-image",
      "linear-gradient(to right, #00092A, #002a94, #008BE9 98%)"
    );

    // 0 SUPER-ADMIN
    // 1 TIENDA
    // 2 USUARIO PERFIL PERSONAL
    this.infoUser = JSON.parse(localStorage.getItem("infoUser"));
    if (this.infoUser) {
      if (this.infoUser.user_type_account == 0) {
        this.menuItems = ROUTESADMIN.filter((menuItem) => menuItem);
      }
      if (this.infoUser.user_type_account == 1) {
        this.menuItems = ROUTESUSER.filter((menuItem) => menuItem);
      }
    }

    var isWindows = navigator.platform.indexOf("Win") > -1 ? true : false;
    isWindows = navigator.platform.indexOf("Win") > -1 ? true : false;
    var $sidebar = $(".sidebar");
    $sidebar.css("background-color", "green");

    if (isWindows) {
      $(".sidebar .sidebar-wrapper, .main-panel").perfectScrollbar();
      $("html").addClass("perfect-scrollbar-on");
    } else {
      $("html").addClass("perfect-scrollbar-off");
    }
  }

  async logout() {
    await this.authSvc.logout();
    this.router.navigate([""]);
  }

  ngAfterViewInit() {
    var $sidebarParent = $(".sidebar .nav > li.active .collapse li.active > a")
      .parent()
      .parent()
      .parent();

    var collapseId = $sidebarParent.siblings("a").attr("href");

    $(collapseId).collapse("show");
  }
}
