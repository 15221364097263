import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth/auth/auth.service';

@Component({
  selector: 'app-navbarwwc',
  templateUrl: './navbarwwc.component.html',
  styleUrls: ['./navbarwwc.component.css']
})
export class NavbarwwcComponent implements OnInit {
  public infoUser: Users;
  constructor( private authService: AuthService,
    private router: Router,) { }

  ngOnInit(): void {
    this.infoUser = JSON.parse(localStorage.getItem("infoUser"));
  }

  logout() {
    this.doLogout();
  };



  doLogout() {
    this.authService.logout();
    this.router.navigate(["/"]);
  }
}
