import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class NoveltyService {
  private last: any;
  constructor(private novelty: AngularFirestore) {}

  saveComentary(commentary: Commentary, novelty_id: string) {
    return this.novelty
      .collection("novelty")
      .doc(novelty_id)
      .collection("comments")
      .doc(commentary.commentary_id)
      .set(commentary);
  }

  updateComentary(commentary: Commentary, novelty_id: string) {
    return this.novelty
      .collection("novelty")
      .doc(novelty_id)
      .collection("comments")
      .doc(commentary.commentary_id)
      .update(commentary);
  }

  //Crea una nueva categoria
  public createNovelty(novelty: Novelty) {
    return this.novelty
      .collection("novelty")
      .doc(novelty.novelty_id)
      .set(novelty);
  }
  //Obtiene una nueva categoria
  public getNovelty() {
    return this.novelty
      .collection("novelty", (ref) => ref.orderBy("novelty_id", "desc"))
      .snapshotChanges();
  }

  public getNoveltyById(novelty_id: string) {
    return this.novelty
      .collection<Novelty>("novelty")
      .doc(novelty_id)
      .snapshotChanges();
  }

  public getComment(novelty_id: string) {
    return this.novelty
      .collection<Commentary>("novelty")
      .doc(novelty_id)
      .collection("comments", (ref) => ref.orderBy("commentary_id", "desc"))
      .snapshotChanges();
  }

  //Obtiene todos las novelty
  public getCategories() {
    return this.novelty.collection("novelty").snapshotChanges();
  }
  //Actualiza una nueva categoria
  public updateNovelty(documentId: string, data: any) {
    return this.novelty.collection("novelty").doc(documentId).set(data);
  }
  //Elimina una nueva categoria
  public deleteNovelty(documentId: string) {
    return this.novelty.collection("novelty").doc(documentId).delete();
  }

  public getNoveltysList(limit) {

    return this.novelty.collection<Novelty>("novelty", (ref) =>
      ref
        .where("novelty_is_live_event", "==", true).where("novelty_is_top", "==", false)
        .orderBy("novelty_id", "desc")
        .limit(limit)
    ).valueChanges();
  }
  public getNoveltysListTop(limit) {

    return this.novelty.collection<Novelty>("novelty", (ref) =>
      ref
        .where("novelty_is_live_event", "==", true).where("novelty_is_top", "==", true)
        .orderBy("novelty_id", "desc")
        .limit(limit)
    ).valueChanges();
  }

  public getNoveltysListViewMoreTree() {
    const next = this.novelty.collection("novelty", (ref) =>
      ref
        .where("novelty_is_live_event", "==", true)
        .orderBy("novelty_id", "asc")
        .startAfter(this.last.data().novelty_id)
        .limit(1)
    );
    const values = next.valueChanges();
    const snapshot = next.get();
    snapshot.forEach((s) => {
      this.last = s.docs[s.docs.length - 1];
    });
    return values;
  }
}
