import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Community } from "app/interfaces/community";
import { User } from "firebase";
import { DatesService } from "../dates/dates.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  public user: Users;
  constructor(
    private fb: AngularFirestore,
    private datesService: DatesService
  ) {}

  async getUserByEmails(uid: string) {
    return this.fb.collection<Users>("users").doc(uid).valueChanges();
  }

  async getUserById(uid: string) {
    return this.fb
      .collection<Users>("users", (ref) =>
        ref.where("user_id", "==", uid).orderBy("user_company_name", "desc")
      )
      .valueChanges();
  }

  public getUserByEmail(user: Users) {
    return this.fb
      .collection<Users>("users")
      .doc(user.user_email)
      .snapshotChanges();
  }

  public getUserByEmailRegister(userEmail: String) {
    return this.fb
      .collection<Users>("users")
      .doc(`${userEmail}`)
      .valueChanges();
  }

  /**
   * *** consultamos los usuarios premier ***
   */
  async getUsersIsPremier() {
    // return this.fb.collection<Users>("users",ref => ref.where('is_premium', '==', true ).where('user_type_account', '==', 1)).snapshotChanges();
    return this.fb
      .collection<Users>("users", (ref) =>
        ref
          .where("is_premium", "==", 1)
          .where("user_stand_completo", "==", true)
          .orderBy("user_company_name", "asc")
      )
      .snapshotChanges();
  }
  async getUsersRegistered() {
    // return this.fb.collection<Users>("users",ref => ref.where('is_premium', '==', true ).where('user_type_account', '==', 1)).snapshotChanges();
   console.log("prueba getUsersRegistered")
    return this.fb
      .collection<Users>("users"
        // , (ref) =>
        // ref
        //   .where("isActive", "==", true)
      )
      .snapshotChanges();
  }
  async getUsersIsPremierIndex() {
    // return this.fb.collection<Users>("users",ref => ref.where('is_premium', '==', true ).where('user_type_account', '==', 1)).snapshotChanges();
    /*
    return this.fb
      .collection<Users>("users", (ref) =>
        ref
          .where("user_type_account", "==", 1)
          .where("is_premium", "==", true)
          .orderBy("user_position_premium", "asc")
      )
      .snapshotChanges();
      */

    return this.fb
      .collection<Users>("users", (ref) =>
        ref.where("user_type_account", "==", 1).where("is_premium", "==", true)
      )
      .snapshotChanges()
      .pipe(
        map(
          (actions) =>
            actions
              .map((a) => {
                const data = a.payload.doc.data() as Users;
                const id = a.payload.doc.id;
                return { id, ...data };
              })
              .sort(
                (a, b) =>
                  (a.user_position_premium ?? 0) -
                  (b.user_position_premium ?? 0)
              ) // Ordenar en el cliente
        )
      );
  }

  /**
   * *** Retorna todas las tiendas ***
   * *** Con estado de cuenta activa ***
   * *** y validada por el usuario ***
   * @param id_category
   */
  async getUsersIsPremierByFilter(id_category: string) {
    if (id_category == "all") {
      return this.fb
        .collection<Users>("users", (ref) =>
          ref
            .where("user_type_account", "==", 1)
            .where("user_state_account", "==", true)
            .where("user_state_validation", "==", true)
            .orderBy("user_company_name", "asc")
        )
        .snapshotChanges();
    } else {
      return this.fb
        .collection<Users>("users", (ref) =>
          ref
            .where("user_type_account", "==", 1)
            .where("array_ids_categorys", "array-contains", id_category)
            .where("user_state_account", "==", true)
            .where("user_state_validation", "==", true)
            .orderBy("user_company_name", "asc")
        )
        .snapshotChanges();
    }
  }

  /**
   * *** consultamos todos los usuarios ***
   */
  async getAllUsers() {
    return this.fb
      .collection<Users>("users", (ref) => ref.orderBy("user_id", "desc"))
      .valueChanges();
  }
  /**Orden para lista de usuarios de manera alfabetica */
  async getUsersAllList() {
    return this.fb
      .collection<Users>("users", (ref) =>
        ref.orderBy("user_register_date", "desc")
      )
      .valueChanges();
  }

  async getUsersAllListStadistics(firstDate: any, ultimateDate: any) {
    return this.fb
      .collection<Users>("users", (ref) =>
        ref
          .where("user_register_date", ">=", firstDate)
          .where("user_register_date", "<=", ultimateDate)
          .orderBy("user_register_date", "desc")
      )
      .valueChanges();
  }
  public getUsersAllListStadisticsByDate(
    actuallDate: any,
    companyName: string
  ) {
    //  console.log('SERVICE',actuallDate);
    return this.fb
      .collection("ubicationUser")
      .doc(`${companyName}`)
      .collection("users", (ref) =>
        ref.where("user_register_date", "==", actuallDate)
      )
      .valueChanges();
  }
  public getUsersAllListStadisticsByDate2(actuallDate: any, novelty: string) {
    //  console.log('SERVICE',actuallDate);
    return this.fb
      .collection("ubicationUserNovelty")
      .doc(`${novelty}`)
      .collection("users", (ref) =>
        ref.where("user_register_date", "==", actuallDate)
      )
      .valueChanges();
  }

  async getUsersAll() {
    return this.fb
      .collection<Users>("users", (ref) =>
        ref.orderBy("user_state_account", "desc")
      )
      .valueChanges();
  }
  async getUsersAllByCountry(user_country: string) {
    return this.fb
      .collection<Users>("users", (ref) =>
        ref
          .where("user_country", "==", user_country)
          .orderBy("user_type_account", "desc")
      )
      .valueChanges();
  }

  public getUsersByCountry(user_country: string) {
    return this.fb
      .collection("users", (ref) =>
        ref.where("user_country", "==", user_country)
      )
      .valueChanges();
  }

  public saveUser(user: Users, infoUser: Users) {
    infoUser.user_company_id = user.user_company_id;
    this.user = user;
    localStorage.setItem("infoUser", JSON.stringify(infoUser));
    return this.fb.collection("users").doc(infoUser.user_email).update(user);
  }

  public addPositionPremium(position: any, user: Users) {
    console.log(user.user_email);
    console.log(position);

    return this.fb.collection("users").doc(user.user_email).update(position);
  }
  public changeStateUsuer(user: Users, state: boolean) {
    if (state) {
      return this.fb
        .collection("users")
        .doc(user.user_email)
        .update({ user_state_account: state });
    } else {
      return this.fb
        .collection("users")
        .doc(user.user_email)
        .update({ user_state_account: state, user_state_validation: state });
    }
  }

  public changeStatePremium(user: Users, state: boolean) {
    // if (state) {
    return this.fb
      .collection("users")
      .doc(user.user_email)
      .update({ is_premium: state });
    // } else {
    // return this.fb.collection("users").doc(user.user_email).update({user_state_account: state, user_state_validation: state})
    // }
  }

  public changeImgStand(user: Users, img: String) {
    return this.fb
      .collection("users")
      .doc(user.user_email)
      .update({ user_image_stand: img });
  }

  public updatePdf(user: Users, url: String) {
    return this.fb
      .collection("users")
      .doc(user.user_email)
      .update({ user_catalogue_product: url });
  }

  public changeStateUsuerValid(user: Users, state: boolean) {
    return this.fb
      .collection("users")
      .doc(user.user_email)
      .update({ user_state_account: state, user_state_validation: state });
  }
  public updateImagePerfil(infoUser: Users, imagePerfil: any) {
    const userPerfil = {
      user_image_perfil: imagePerfil,
    };
    this.fb.collection("users").doc(infoUser.user_email).update(userPerfil);
  }
  // public getCompany(infoUser) {
  //   return this.fb
  //     .collection("companies")
  //     .doc(infoUser.user_country.toLowerCase())
  //     .collection("company")
  //     .doc(infoUser.user_company_id)
  //     .valueChanges();
  // }

  public deleteInterest(infoUser: Users) {
    this.fb.collection("users").doc(infoUser.user_email).update(infoUser);
  }

  public getUsersByCategory(categoyId: String) {
    return this.fb
      .collection("users", (ref) =>
        ref
          .where("array_ids_categorys", "array-contains", categoyId)
          .where("user_type_account", "==", 2)
      )
      .valueChanges();
  }

  public getUsersByCategoryByCountry(categoyId: String, user_country: String) {
    return this.fb
      .collection("users", (ref) =>
        ref
          .where("array_ids_categorys", "array-contains", categoyId)
          .where("user_type_account", "==", 2)
          .where("user_country", "==", user_country)
      )
      .valueChanges();
  }

  public getUsersByCategoryByInterest(categoyId: String) {
    return this.fb
      .collection("users", (ref) =>
        ref.where("user_categorys", "array-contains", categoyId)
      )
      .valueChanges();
  }

  public saveNotificationInUser(users: Users, notificationId: string) {
    const notification = {
      notification_id: notificationId,
      notification_status: false,
    };
    this.fb
      .collection("users")
      .doc(`${users.user_email}`)
      .collection("notifications")
      .doc(`${notificationId}`)
      .set(notification);
  }

  public getAllUsersClient() {
    return this.fb
      .collection("users", (ref) => ref.where("user_type_account", "==", 2))
      .valueChanges();
  }

  public getAllUsersOfCountry(country: String) {
    return this.fb
      .collection("users", (ref) => ref.where("user_country", "==", country))
      .valueChanges();
  }

  public getNotificationsFromUsers(userEmail: String) {
    return this.fb
      .collection("users")
      .doc(`${userEmail}`)
      .collection("notifications", (ref) =>
        ref
          .where("notification_status", "==", false)
          .orderBy("notification_id", "desc")
      )
      .valueChanges();
  }

  public updateStatusNotification(notificationId: String, userEmail: String) {
    const status = {
      notification_status: true,
    };
    this.fb
      .collection("users")
      .doc(`${userEmail}`)
      .collection("notifications")
      .doc(`${notificationId}`)
      .update(status);
  }

  public updateStatusNotificationSaved(
    notificationId: String,
    userEmail: String
  ) {
    const status = {
      notification_status: true,
    };
    this.fb
      .collection("users")
      .doc(`${userEmail}`)
      .collection("notificationsSaved")
      .doc(`${notificationId}`)
      .update(status);
  }

  public updateStatusNotificationSavedBorrar(
    notificationId: String,
    userEmail: String
  ) {
    const status = {
      notification_status: true,
    };
    this.fb
      .collection("users")
      .doc(`${userEmail}`)
      .collection("notificationsSaved")
      .doc(`${notificationId}`)
      .update(status);
  }

  public saveNotification(notificationId: String, users: Users) {
    const notification = {
      notification_id: notificationId,
      notification_status: false,
    };
    this.fb
      .collection("users")
      .doc(`${users.user_email}`)
      .collection("notificationsSave")
      .doc(`${notificationId}`)
      .set(notification);
  }

  public deleteNotification(notificationId: String, users: Users) {
    this.fb
      .collection("users")
      .doc(`${users.user_email}`)
      .collection("notificationsSave")
      .doc(`${notificationId}`)
      .delete();
  }

  public getNotificationsSavedFromUsers(userEmail: String) {
    return this.fb
      .collection("users")
      .doc(`${userEmail}`)
      .collection("notificationsSave", (ref) =>
        ref.where("notification_status", "==", false)
      )
      .valueChanges();
  }

  public addCategory(userEmail: String, category: Category) {
    this.fb.collection("users").doc(`${userEmail}`).update(category);
  }

  public updateData(userEmail: String, data: Object) {
    this.fb.collection("users").doc(`${userEmail}`).update(data);
  }

  public getCategoryId(categoryId: String) {
    return this.fb.collection("category").doc(`${categoryId}`).valueChanges();
  }

  public savePublicationInCollectionInteresting(
    userEmail: String,
    community: Community
  ) {
    const publication: Community = {
      publication_id: community.publication_id,
    };
    this.fb
      .collection("users")
      .doc(`${userEmail}`)
      .collection("publicationsOfInterest")
      .doc(`${community.publication_id}`)
      .set(publication);
  }

  public savePublicationInAllUsers(userEmail: String, community: Community) {
    const publication: Community = {
      publication_id: community.publication_id,
    };
    this.fb
      .collection("users")
      .doc(`${userEmail}`)
      .collection("publications")
      .doc(`${community.publication_id}`)
      .set(publication);
  }

  public getPublicationsByUserInteresting(user_email: String) {
    return this.fb
      .collection("users")
      .doc(`${user_email}`)
      .collection("publicationsOfInterest", (ref) =>
        ref.orderBy("publication_id", "asc")
      )
      .valueChanges();
  }

  public getPublications(user_email: String) {
    return this.fb
      .collection("users")
      .doc(`${user_email}`)
      .collection("publications", (ref) =>
        ref.orderBy("publication_id", "desc")
      )
      .valueChanges();
  }

  public deleteUserStand(user: Users) {
    return this.fb.collection("users").doc(`${user.user_email}`).delete();
  }
}
