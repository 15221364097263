import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { Community } from 'app/interfaces/community';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {

  constructor(private db: AngularFirestore) { }

  public saveWishlitInUser(dataId: String, data: Community, userEmail: String) {
    data.wishListAddTime = new Date().getTime().toString()
    this.db.collection('users').doc(`${userEmail}`).collection('wishlistPublications').doc(`${dataId}`).set(data)
  }

  public deleteWishlitInUser(dataId: String,userEmail : String) {
    this.db.collection('users').doc(`${userEmail}`).collection('wishlistPublications').doc(`${dataId}`).delete()
  }
  public saveWishlitProductInUser(dataId: String, data: any, userEmail : String, user_id: string,  user_id_stand: string, user_whatsapp: string) {
    data.wishListAddTime = new Date().getTime().toString()
    data.user_id = user_id
    data.user_email= userEmail
    data.user_id_stand = user_id_stand
    data.user_whatsapp = user_whatsapp
    this.db.collection('users').doc(`${userEmail}`).collection('wishlistProduct').doc(`${dataId}`).set(data)
  }

  public deleteWishlitProductInUser(dataId: String,userEmail : String) {
    this.db.collection('users').doc(`${userEmail}`).collection('wishlistProduct').doc(`${dataId}`).delete()
  }

  public getWishlistPublications(userEmail : String) {
    return this.db.collection('users').doc(`${userEmail}`).collection('wishlistPublications', ref => ref
    .orderBy('wishListAddTime', 'desc')).valueChanges();
  }
  public getWishlistProducts(userEmail: String) {
    return this.db.collection('users').doc(`${userEmail}`).collection('wishlistProduct', ref => ref
    .orderBy('wishListAddTime', 'desc')).valueChanges()
  }
}
