import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
// import { User } from 'firebase';
@Injectable({
  providedIn: "root",
})
export class RegisterService {
  constructor(private firestore: AngularFirestore) {}

  /**
   * crear una funcion
   * que reciba el usuario
   * imprimir el usuario que recibe solo para cer que funcione
   */

  /**
   * funcion para registrar un usuario en la base de datos,
   * recibe toda la informacion del usuario(todo el formulario )
   * @param user
   */
  async registerUser(user: Users) {
    console.log(user);
    
    //this.sendEmailNewRequest(user);

    return this.firestore.collection("users").doc(user.user_email).set(user);
  }

  public async registerUserTypeTwo(user: Users) {
    this.firestore.collection("users").doc(user.user_email).update(user);
  }

  async sendverifyCode(user: Users) {
    let code = new Date().getTime().toString().substring(7);
    var infoCode = {
      code: code,
      time: user.user_register_time,
      date: user.user_register_date,
      email: user.user_email,
    };
    await this.firestore
      .collection("verification_codes")
      .doc(user.user_email)
      .set(infoCode);
  }

  sendEmailNewRequest(user: Users) {
    // console.log('*** sendEmailNewRequest ***');
    // console.log(user);
    
    var infoEmeil = {
      user_address: user.user_address,
      user_company_name: user.user_company_name,
      user_country: user.user_country,
      user_description_services: user.user_description_services,
      user_email: user.user_email,
      user_lastname: user.user_lastname,
      user_name: user.user_name,
      user_phone: user.user_whatsapp,
      user_register_date: user.user_register_date,
      user_register_time: user.user_register_time,
    };
    console.log(infoEmeil);
    
    this.firestore
      .collection("emails").doc("new_request").collection("send_email")
      .doc(user.user_email)
      .set(infoEmeil);
  }

  public validCode(user: Users) {
    return this.firestore
      .collection("verification_codes")
      .doc(user.user_email)
      .snapshotChanges();
  }

  async otra(otro) {}
}
