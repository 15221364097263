import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { last, mergeMap, } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private storage: AngularFireStorage) {
    /**
* Cargar archivo de imagen.
* @param filePath
* @param file
*/

  }
  public uploadFile(filePath: string, file: File): Promise<string> {
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    //console.log("hola", filePath);
    
    return task.snapshotChanges().pipe(
      last(),
      mergeMap(() => fileRef.getDownloadURL())
    ).toPromise();
  }
}
